<template>
    <div class="jc-component__range">
        <div class="jc-range" :class="rangeStatus?'success':''">
            <!--滑块的图标-->
            <i @mousedown="rangeMove" :class="rangeStatus?successIcon:startIcon" ref="sliderIcon"/>
            <!--滑块的文字-->
            {{rangeStatus?successText:startText}}
        </div>
    </div>
</template>
<script>
    import { ref,computed,getCurrentInstance,reactive,toRefs,onMounted,defineComponent} from 'vue';
    export default defineComponent({
        props: {
            // 成功之后的函数
            successFun: {
                type: Function
            },
            //成功图标
            successIcon: {
                type: String,
                default: "el-icon-success"
            },
            //成功文字
            successText: {
                type: String,
                default: "验证成功"
            },
            //开始的图标
            startIcon: {
                type: String,
                default: "el-icon-d-arrow-right"
            },
            //开始的文字
            startText: {
                type: String,
                default: "拖动滑块到最右边"
            },
            //失败之后的函数
            errorFun: {
                type: Function
            },
            //或者用值来进行监听
            status: {
                type: String
            }
        },
        name: "Silder",
        setup(props,context){
            const {proxy}=getCurrentInstance();
            let dataObj=reactive({
                sliderIcon:'',//滑块图标引用
                rangeStatus:'',//滑块状态
                disX:0
            })
            //滑块鼠标按下事件
            const rangeMove=(e)=>{
                let ele = e.target;
                let startX = e.clientX;
                let eleWidth = ele.offsetWidth;//滑块的宽度
                let parentWidth =  ele.parentElement.offsetWidth;//滑块父类div的宽度
                let MaxX = parentWidth - eleWidth;//终点位置(滑块父类div的宽度-滑块的宽度极为最终滑块的位置)
                if(dataObj.rangeStatus){//如果当前滑块的状态为验证通过状态，则不允许再次拖动图标了
                    return false;
                }
                //滑块按住滑动事件，计算滑动的最终位置
                document.onmousemove = (e) => {
                    let endX = e.clientX;
                    dataObj.disX = endX - startX;
                    if(dataObj.disX<=0){
                        dataObj.disX = 0;
                    }
                    if(dataObj.disX>=MaxX-eleWidth){//减去滑块的宽度,体验效果更好
                        dataObj.disX = MaxX;
                    }
                    ele.style.transition = '.1s all';
                    ele.style.transform = 'translateX('+dataObj.disX+'px)';
                    e.preventDefault();
                }
                document.onmouseup = ()=> {
                    if(dataObj.disX !== MaxX){
                        ele.style.transition = '.5s all';
                        ele.style.transform = 'translateX(0)';
                        //执行失败的函数
                        props.errorFun && props.errorFun();
                    }else{
                        dataObj.rangeStatus = true;
                        if(props.status){
                            proxy.$parent[props.status] = true;
                        }
                        //执行成功的函数
                        props.successFun && props.successFun();
                    }
                    document.onmousemove = null;
                    document.onmouseup = null;
                }
            }
            //把滑块复原
            const resetSlider=()=>{
                dataObj.rangeStatus='';
                dataObj.disX=0;
                dataObj.sliderIcon.style.transform = 'translateX(0)';
            }
            return{
                ...toRefs(dataObj),rangeMove,resetSlider
            }
        }
    });
</script>
<style scoped>
    /*由于设置了form-item的margin-buttom为0，会造成登录按钮和滑动验证组件挤在一起了，所以在这里往下挤8px*/
    .jc-component__range{
        margin-bottom: 8px;
    }
    .jc-range{
        background-color: #FFCCCC;
        position: relative;
        transition: 1s all;
        user-select: none;
        color: #333;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px; /*no*/
    }
    .jc-range i{
        position: absolute;
        left: 0;
        width: 60px;/*no*/
        height: 100%;
        color: #919191;
        background-color: #fff;
        border: 1px solid #bbb;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .jc-range.success{
        background-color: #7AC23C;
        color: #fff;
    }
    .jc-range.success i{
        color: #7AC23C;
    }
</style>