import {ref,getCurrentInstance, reactive, toRefs, onMounted, inject, defineComponent} from 'vue';
const GridTableHelper = defineComponent({
    name: "GridTable",
    props: {
        modelMethod: {//表格要请求的路径
            type: String
        },
        canPage: {//是否分页
            type: Boolean,
            default: true
        },
        tbPagerInitParam: {//分页参数
            type: Object,
            default: function () {
                return {
                    small:false,
                    pagerCount:7,
                    pageSize: 20,
                    pageSizes: [20, 50, 100, 200, 500],
                    layout:'total, sizes, prev, pager, next, jumper'
                };
            }
        },
        queryParam: {
            type: Object,
            default:()=>{return{}}
        },
        isShowIndexCol: {
            type: Boolean,
            default: true
        },
        autoLoad: {
            type: Boolean,
            default: true
        },
        staticData: {
            type: Boolean,
            default: false
        },
        //--------------------树形表格参数--------------------
        //详情参看官方文档
        lazy: {
            type: Boolean,
            default: false
        },
        rowKey:{
            type: String,
            default: ""
        },
        expandAll: {
            type: Boolean,
            default: false
        },
        treeProps: {
            type: Object,
            default: function () {
                return {
                    children: 'children', hasChildren: 'hasChildren'
                };
            }
        },
        //--------------------树形表格参数--------------------
        showTitle: {//是否显示表格标题
            type: Boolean,
            default: true
        },
        title: {//表格标题
            type: String,
            default: ''
        },
        showToolBar: {//是否显示表格工具栏
            type: Boolean,
            default: false
        },
        showSummary: {//是否在表尾显示合计行
            type: Boolean,
            default: false
        },
        tbId:{//表格id，一个页面有多个表格的时候，在父类作区分的时候用
            type: String,
            default: 'defTbId'
        }
    },
    setup(props,context){
        let gridTableOwner = inject('gridTableOwner');//要使用表格，需要外界注入gridTableOwner，即使用对象是谁
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        const tbRef=ref(null);
        let dataObj=reactive({
            loading: false,
            title:'',
            hasSelection:false,
            tbData: [],//表格的数据
            tbCols: {},
            pagerHeight:32,
            currentPage: 1,//当前页
            total: 0,//总条数，不是总页数
            maxTbHeight:500//默认表格的最大高度
        })

        onMounted(async()=>{
            getTbColumn();
            if(props.autoLoad) await queryHandler(true);
            if(props.title)dataObj.title=props.title;
        })
        //获取表格列
        const getTbColumn=()=>{
            const labels = [];
            const fields = [];
            let _tbCols=context.slots._tbCols();
            for (let i = 0; i < _tbCols.length; i++) {
                if('_tbCols'==_tbCols[i].key){
                    let gridColumns=_tbCols[i].children;
                    for (let j = 0; j < gridColumns.length; j++) {
                        if(typeof(gridColumns[j].type)!='object')continue;
                        let label=gridColumns[j].props.label;
                        let prop=gridColumns[j].props.prop;
                        if(label)labels.push(label);
                        if(prop)fields.push(prop);
                        if(!prop){
                            let cols=gridColumns[j].children.default();
                            for(let k=0;k<cols.length;k++){
                                if(cols[k].props && cols[k].props.label)labels.push(cols[k].props.label);
                                if(cols[k].props && cols[k].props.prop)fields.push(cols[k].props.prop);
                            }
                        }
                    }
                }else{
                    if(_tbCols[i].children && !dataObj.hasSelection){
                        for (let j = 0; j < _tbCols[i].children.length; j++) {
                            if(typeof(_tbCols[i].children[j].type)!='object')continue;
                            const props=_tbCols[i].children[j].props;
                            if(props && props.type && props.type=='selection'){
                                dataObj.hasSelection=true;
                                break;
                            }
                        }
                    }
                }
            }
            dataObj.tbCols.labels = labels;
            dataObj.tbCols.fields = fields;
        }
        const queryHandler=async(isNewQuery,options)=>{
            dataObj.loading = true;
            if(isNewQuery)dataObj.currentPage=1;
            if (!props.staticData && props.modelMethod) {
                props.queryParam.pageSize = props.tbPagerInitParam.pageSize;
                props.queryParam.currentPage = dataObj.currentPage;
                props.queryParam.canPage = props.canPage;
                try {
                    if(options){
                        props.queryParam=Object.assign(props.queryParam, options);
                    }
                    let res = await utils.$$api.postRequest({url: props.modelMethod, params: props.queryParam});
                    if(res.result){
                        dataObj.loading = false;
                        dataObj.tbData = res.rows;
                        dataObj.total = res.total;
                        if (gridTableOwner && gridTableOwner.gridLoaded) {
                            await gridTableOwner.gridLoaded(res,props.tbId);
                        }
                    }else{
                        utils.$$tools.error();
                    }
                } catch (res) {
                    console.log(res);
                }
            }
            if(dataObj.loading)dataObj.loading=false;
        }
        //重新加载表格
        const reloadGrid=async (options)=>{
            await queryHandler(true,options);
        }
        //为序号列赋值
        const indexMethod=(index)=>{
            return index+1;
        }
        const rowClassName=({row, rowIndex})=>{
            row.rowIndex = rowIndex;
        }
        //设置表格最大高度
        const setMaxTbHeight=(maxTbHeight)=>{
            dataObj.maxTbHeight=maxTbHeight;
            // dataObj.maxTbHeight=400;
        }
        const setPagerHeight=(pagerHeight)=>{
            dataObj.pagerHeight=pagerHeight;
        }
        //表格行改变事件
        const currentChange=(currentRow, oldCurrentRow)=>{
            if (gridTableOwner && gridTableOwner.currentChange) {
                gridTableOwner.currentChange({currentRow: currentRow, oldCurrentRow: oldCurrentRow,tbId:props.tbId});
            }
        }
        //获取当前表格数据
        const getTbData=()=>{
            return dataObj.tbData?dataObj.tbData:[];
        }
        //为表格设置数据，不用向后台发送请求
        const setTbData=(tbData)=>{
            dataObj.tbData = tbData;
            dataObj.total = tbData.length;
            if (gridTableOwner && gridTableOwner.gridLoaded) {
                gridTableOwner.gridLoaded({tbData: tbData,tbId:props.tbId});
            }
        }
        //清除表格数据
        const clearTbData=()=> {
            dataObj.total =0;
            dataObj.tbData.splice(0, dataObj.tbData.length);
        }
        //表格每页显示多少条记录改变事件
        const sizeChangeHandler=(val)=>{
            props.tbPagerInitParam.pageSize = val;
            queryHandler(true);
        }
        //上一页、下一页、点击具体页事件
        const currentChangeHandler=(val)=>{
            dataObj.currentPage = val;
            queryHandler(false);
        }
        //升序/降序事件
        const sortChange=(column, prop, order)=>{
            props.queryParam.orderField = column.prop;
            let orderType="asc"
            if(column.order=='descending'){
                orderType="desc";
            }
            props.queryParam.orderType =orderType;
            queryHandler(true);
        }
        const selectionChange=(selection)=>{
            if(gridTableOwner.selectionChange)
                gridTableOwner.selectionChange({selection: selection,tbId:props.tbId});
        }
        //点击行事件
        const clickTableRow=(row, column, event)=>{
            if (gridTableOwner && gridTableOwner.clickTableRow) {
                gridTableOwner.clickTableRow({row: row, column: column,tbId:props.tbId});
            }
            if(!dataObj.hasSelection)tbRef.value.clearSelection();
            toggleRowSelection(row);
        }
        const checkBoxHandler=(selection, row)=>{
            if (gridTableOwner && gridTableOwner.checkBoxHandler) {
                gridTableOwner.checkBoxHandler({selection: selection, row: row,tbId:props.tbId});
            }
        }
        const checkBoxAllHandler=(selection)=>{
            if (gridTableOwner && gridTableOwner.checkBoxAllHandler) {
                gridTableOwner.checkBoxAllHandler({selection: selection,tbId:props.tbId});
            }
        }
        const getSelection=()=>{
            return tbRef.value.store.states.selection.value;
        }
        const toggleRowSelection=(row)=>{
            tbRef.value.toggleRowSelection(row);
        }
        const treeLazyLoad=(tree, treeNode, resolve)=>{
            if (gridTableOwner && gridTableOwner.treeLazyLoad) {
                gridTableOwner.treeLazyLoad(props.tbId,tree, treeNode, resolve);
            }
        }
        const getSummaries=(param)=>{
            if(props.showSummary && (gridTableOwner && gridTableOwner.getSummaries)){
                return gridTableOwner.getSummaries(props.tbId,param);
            }else{
                return [];
            }
        }
        const cellClick=(row, column, cell, event)=>{
            if (gridTableOwner && gridTableOwner.cellClick) {
                gridTableOwner.cellClick(props.tbId,row, column, cell, event);
            }
        }
        //单双击单元格事件
        const cellDblclick=(row, column, cell, event)=>{
            if (gridTableOwner && gridTableOwner.cellDblclick) {
                gridTableOwner.cellDblclick(props.tbId,row, column, cell, event);
            }
        }
        //表格标题
        const buildTitle=()=>{
            if (gridTableOwner && gridTableOwner.buildTitle) {
                return gridTableOwner.buildTitle(props.tbId, proxy);
            }else{
                return props.title;
            }
        }
        //设置表格标题
        const setTitle=(title)=>{
            dataObj.title=title;
        }
        return{
            ...toRefs(dataObj),getTbColumn,queryHandler,reloadGrid,indexMethod,rowClassName,tbRef,getTbData,setTbData,clearTbData,
            currentChange,sizeChangeHandler,currentChangeHandler,sortChange,selectionChange,getSelection,toggleRowSelection,
            clickTableRow,checkBoxHandler,checkBoxAllHandler,treeLazyLoad,getSummaries,setMaxTbHeight,cellClick,cellDblclick,
            buildTitle,setPagerHeight,setTitle
        }
    }
});

export default GridTableHelper;