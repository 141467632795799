const GRIDLOADED="gridLoaded";
const ONHIDDEN="onHidden";
const SELECTALL="selectAll";
const SHOW="show";
const CLICKTABLEROW="clickTableRow";
const EDITHANDLER="editHandler";
const REFRESHHANDLER="refreshHandler";
const ONCHANGE="comboGridOnChange";
const updateKey='update:modelValue';
import {ref, computed, getCurrentInstance, reactive, toRefs, onMounted, defineComponent, watch, provide, nextTick} from 'vue';
const ComboGridHelper = defineComponent({
    name: "comboGrid",
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        comboParam: {
            type: Object,
            default: function () {
                return {
                    disabled:false,
                    showToolBar:false,
                    multiple: false
                }
            }
        }
    },
    setup(props,context){
        const {proxy} = getCurrentInstance();
        const utils=proxy.utils;
        provide('gridTableOwner',proxy);
        let dataObj=reactive({
            visible:false,
            popoverRef:null,
            comboGridRef:null,
            comboId:'comboId',
            readOnly:false,
            disabled:false,
            txtField:"F_NAME",
            idField:"F_ID",
            comboText: "",
            searchFieldsArray:[],
            gridParam: {
                tbId:props.comboParam.comboId?props.comboParam.comboId:'comboId',
                showTitle:props.comboParam.showTitle?props.comboParam.showTitle:false,
                showToolBar:props.comboParam.showToolBar?props.comboParam.showToolBar:false,
                canPage: !props.comboParam.multiple,
                autoLoad:false,
                queryParam: Object.assign({}, {combo: "combo",searchText: ""}, props.comboParam),
                tbPagerInitParam:{
                    small:true,
                    pagerCount:5,
                    pageSize: 10,
                    layout:'prev, pager, next, jumper'
                },
                modelMethod:props.comboParam.modelMethod
            }
        });

        onMounted(async ()=>{
            await init(props.comboParam);
        });
        const init=async (params)=>{
            nextTick(async()=>{
                if(params.idField)dataObj.idField=params.idField;
                if(params.txtField)dataObj.txtField=params.txtField;

                if(params.searchFieldsArray){
                    dataObj.searchFieldsArray=params.searchFieldsArray;
                }else{
                    dataObj.searchFieldsArray=dataObj.comboGridRef.tbCols.fields;
                }
                if(params.readOnly)dataObj.readOnly=params.readOnly;
                if(params.multiple)dataObj.readOnly=true;
                if(params.disabled)dataObj.disabled=params.disabled;
                if(utils.$$str.isNotEmpty(params.modelMethod) && utils.$$str.isNotEmpty(props.modelValue) && utils.$$str.isEmpty(dataObj.comboText)){
                    await getTextById();
                }
                if(!params.maxHeight)params.maxHeight=200;
                if(dataObj.comboGridRef)dataObj.comboGridRef.setMaxTbHeight(params.maxHeight);
                if(params.comboId)dataObj.comboId =params.comboId;
            })
        }
        watch(() => props.modelValue,async (newValue,oldValue) => {
                if ("" == newValue) {
                    dataObj.comboText = "";
                } else {
                    if(utils.$$str.isNotEmpty(props.comboParam.modelMethod) && utils.$$str.isEmpty(dataObj.comboText)){
                        await getTextById();
                    }
                }
                if(null!=newValue)context.emit(ONCHANGE, newValue, oldValue,dataObj.comboId);

                if(newValue!=null && props.comboParam.comboId){

                    if(props.comboParam.formRef)props.comboParam.formRef.validateField(dataObj.comboId);
                }
            })

        watch(() => props.comboParam,async (newValue,oldValue) => {
            await init(newValue);
            dataObj.gridParam.queryParam=Object.assign({}, {combo: "combo",searchText: ""}, props.comboParam);
        })

        const getTextById=async()=>{
            if (utils.$$str.isNotEmpty(props.modelValue)) {
                if (props.comboParam.multiple) {
                    if(props.comboParam.mulComboText)dataObj.comboText = props.comboParam.mulComboText;
                } else {
                    if(props.comboParam.content){
                        let item=props.comboParam.content.find((row) => row[dataObj.idField] == props.modelValue);
                        if(item)dataObj.comboText = item[dataObj.txtField];
                    }else{

                        if(props.comboParam.comboText){
                            dataObj.comboText = props.comboParam.comboText;
                        }else{
                            let modelPathArr=props.comboParam.modelMethod.split('/');
                            let modelPath="/"+modelPathArr[1];
                            let params= Object.assign({comboId:dataObj.comboId},props.comboParam,{ id: props.modelValue },{comboType:'comboGrid'});
                            const res=await utils.$$api.postRequest({url: modelPath + "/getTextById",params: params});
                            if(res.result)dataObj.comboText = res.comboText;
                        }
                    }
                }
            }
        }
        const gridLoaded=(response,tbId)=>{
            context.emit(GRIDLOADED, response,tbId);
        }
        //下拉框搜索事件
        const inputChange=(value)=>{
            if (value == "")context.emit(updateKey,'');
            if(props.comboParam.content){
                let tempContent=props.comboParam.content.slice(0);
                props.comboParam.content.forEach((row, index)=> {
                    let item=dataObj.searchFieldsArray.find((field) => row[field].indexOf(value)>-1);
                    if(!item)tempContent.splice(index,1);
                });
                dataObj.comboGridRef.setTbData(tempContent);
            }else{
                dataObj.gridParam.queryParam.searchText = value;
                dataObj.comboGridRef.queryHandler(true);
            }
        }
        const clickTableRow=async(options)=>{
            let row = options.row;
            if (!props.comboParam.multiple) {
                context.emit(CLICKTABLEROW, row,dataObj.comboId);
                if (row[dataObj.txtField] != dataObj.comboText) {
                    dataObj.comboText = row[dataObj.txtField];
                }
                await context.emit(updateKey,row[dataObj.idField]);
                dataObj.visible=false;
            }else{

            }

        }
        const selectionChange=(options)=>{
            if (props.comboParam.multiple){
                let selection = options.selection;
                let _comboText = "", _comboValue = "";
                selection.forEach((row)=>{
                    _comboValue = _comboValue + row[dataObj.idField] + ",";
                    _comboText = _comboText + row[dataObj.txtField]+ ",";
                });

                _comboValue = _comboValue.trim().substr(0, _comboValue.trim().length - 1);
                _comboText = _comboText.trim().substr(0, _comboText.trim().length - 1);
                dataObj.comboText = _comboText;
                context.emit(updateKey,_comboValue);//触发事件，改变下拉框的值
            }
        }
        const selectAll=(options)=>{
            selectionChange(options);
        }
        //下拉面板影藏事件
        const hide=()=>{
            let tbData = dataObj.comboGridRef.getTbData();
            if (tbData.length != 0  && utils.$$str.isNotEmpty(dataObj.gridParam.queryParam.searchText)) {
                dataObj.comboText = tbData[0][[dataObj.txtField]];
                context.emit(updateKey,tbData[0][dataObj.idField]);
            } else if (tbData.length == 0) {
                dataObj.comboText = "";
                context.emit(updateKey,'');
            }
            context.emit(ONHIDDEN, {comboValue:props.modelValue,comboId:dataObj.comboId});
            dataObj.gridParam.queryParam.searchText = "";
        }
        //下拉面板显示事件
        const show=async()=>{
            if(props.comboParam.content){
                dataObj.comboGridRef.setTbData(props.comboParam.content);
            }else{
                let tbData = dataObj.comboGridRef.getTbData();
                if(tbData.length==0)await dataObj.comboGridRef.queryHandler();
                if(utils.$$str.isNotEmpty(props.modelValue))selectByComboValue();
            }
            context.emit(SHOW, {comboId:dataObj.comboId});
        }
        const selectByComboValue=()=>{
            let tbRef=dataObj.comboGridRef.tbRef;
            let tbData = dataObj.comboGridRef.getTbData();
            tbRef.clearSelection();
            tbData.forEach((row)=> {
                if (props.comboParam.multiple) {
                    if (props.modelValue.indexOf(row[dataObj.idField]) > -1) tbRef.toggleRowSelection(row,true);
                } else {
                    if (row[dataObj.idField] == props.modelValue) tbRef.setCurrentRow(row);
                }
            });
        }
        const setQueryParams=(appendParams)=>{
            dataObj.gridParam.queryParam = Object.assign(dataObj.gridParam.queryParam, appendParams);
            dataObj.comboGridRef.queryHandler(true);
            dataObj.comboText = "";
        }

        const inputClear=async ()=>{
            dataObj.comboText = "";
            context.emit(updateKey,'');
            dataObj.gridParam.queryParam.searchText = "";
            await refreshHandler();
        }
        const editHandler=()=>{
            context.emit(EDITHANDLER, {comboId:dataObj.comboId});
        }
        const refreshHandler=async()=>{
            await dataObj.comboGridRef.queryHandler(true);
            let tbData = dataObj.comboGridRef.getTbData();
            context.emit(REFRESHHANDLER, {tbData:tbData,comboId:dataObj.comboId});
        }
        const getTbInst=()=>{
            return dataObj.comboGridRef;
        }
        const setDisable=(val)=>{
            dataObj.disabled=val;
        }
        return{
            ...toRefs(dataObj),getTextById,gridLoaded,inputChange,clickTableRow,selectionChange,
            selectAll,hide,show,setQueryParams,selectByComboValue,inputClear,editHandler,
            refreshHandler,getTbInst,setDisable
        }
    },
    components: {
         
    }
});
export default ComboGridHelper;