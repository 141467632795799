<template>
    <div class="sysConfig">
        <el-container>
            <el-header>
                <el-row>
                    <template v-for="btn in cardParam.cardButtons">
                        <el-button :type="btn.type" :icon="btn.icon" @click="clickHandler(btn.clickEvent)" v-loading.fullscreen.lock="fullscreenLoading">{{btn.title}}</el-button>
                    </template>
                </el-row>
            </el-header>
            <el-main class="content">
                <el-form ref="formRef" :model="form" :rules="rules" label-width="180px">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="是否启用验证码">
                                <el-checkbox v-model="form.useYzm"></el-checkbox>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" v-show="form.useYzm">
                            <el-form-item label="验证码种类">
                                <el-select v-model="form.yzmType" placeholder="请选择验证码种类">
                                    <el-option label="滑块验证码" value="0"/>
                                    <el-option label="数字验证码" value="1"/>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="密码最小长度">
                                <el-input-number v-model="form.pwdMinLen" :min="1" :max="99"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="密码最多错误次数">
                                <el-input-number v-model="form.pwdWrongNum" :min="1" :max="9"/>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="技术支持联系方式">
                        <el-input @input="e => form.linkWay = validForbid(e)" v-model="form.linkWay" maxlength="100" show-word-limit clearable/>
                    </el-form-item>
                    <el-divider></el-divider>

                    <el-row>
                        <el-form-item label="token过期处理方式">
                            <el-select v-model="form.tokenOutTime" placeholder="请选择token过期处理方式">
                                <el-option label="重新生成返回给客户端" value="0"/>
                                <el-option label="强退重新登录" value="1"/>
                            </el-select>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="是否保存操作日志到数据库">
                                <el-checkbox v-model="form.saveOperateLogToDb"></el-checkbox>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="是否保存操作日志到缓存">
                                <el-checkbox v-model="form.pushOperateLogToBuffer"></el-checkbox>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import { ref,computed,getCurrentInstance,reactive,toRefs,onMounted,defineComponent} from 'vue';
    import {mapActions} from "vuex";
    let form = {
        id: "",
        pwdMinLen: 0,
        pwdWrongNum: 0,
        useYzm: true,
        yzmType:'0',
        linkWay: "",
        tokenOutTime:"0",
        saveOperateLogToDb:"1",
        pushOperateLogToBuffer:"1"
    };
    export default defineComponent({
        name: "SysConfig",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let store = proxy.$store;
            let actions=mapActions(["configEncryptData"]);
            let configEncryptData = actions.configEncryptData.bind({ $store: store });
            let dataObj=reactive({
                formRef:null,
                fullscreenLoading:false,
                cardParam: {
                    cardButtons: [],
                    modelPath: "/config"
                },
                form: Object.assign({}, form),

                rules: {

                }
            })
            onMounted(()=>{

            })
            const clickHandler=(ev)=>{
                proxy[ev]();
            }
            const requestCard=async()=>{
                dataObj.fullscreenLoading=true;
                let res = await utils.$$api.postRequest({
                    params:{modelType:'card'},
                    url: dataObj.cardParam.modelPath + "/load"
                });
                dataObj.form = res.data;
                //处理以下特殊的返回结果
                dataObj.form.pwdMinLen = parseInt(res.data.pwdMinLen);
                dataObj.form.pwdWrongNum = parseInt(res.data.pwdWrongNum);
                dataObj.form.useYzm = (res.data.useYzm == "1" ? true : false);
                dataObj.form.tokenOutTime = res.data.tokenOutTime;
                dataObj.form.saveOperateLogToDb = (res.data.saveOperateLogToDb == "1" ? true : false);
                dataObj.form.pushOperateLogToBuffer = (res.data.pushOperateLogToBuffer == "1" ? true : false);

                dataObj.cardParam.cardButtons = res.buttons;
                dataObj.fullscreenLoading=false;
            }
            //保存
            const saveHandler=async()=>{
                try {
                    dataObj.fullscreenLoading=true;
                    let res = await utils.$$api.postRequest({
                        url: dataObj.cardParam.modelPath + "/save",
                        params: JSON.stringify(dataObj.form)
                    });
                    if(res.result){
                        configEncryptData(dataObj.form.encryptData);//设置是否加密传输数据到vuex
                        sessionStorage.setItem('mainMsg',dataObj.form.mainMsg);
                        utils.$$tools.success({
                            message: res.msg
                        });
                    }
                    dataObj.fullscreenLoading=false;
                } catch (ex) {
                    dataObj.fullscreenLoading=false;
                    utils.$$tools.error({
                        message: res.msg
                    });
                }
            }

            //重置
            const resetHandler=()=>{
                requestCard();
            }
            requestCard();
            return{
                ...toRefs(dataObj),clickHandler,requestCard,saveHandler,resetHandler
            }
        },

    });
</script>

<style scoped>
    .content {
        padding-right: 80px;
    }

    .el-header {
        padding: 10px 20px 40px !important;
        height: 30px !important;
    }
</style>
