import utils from "./jh.core";

export default {
    //发送post请求
    postRequest:async (options)=>{
        return await utils.$$post({ url: options.url, params: options.params });
    },
    //动态生成id
    getNewId:async(options)=>{
        let res = await utils.$$post({ url: "/utils/getNewId", params: options });
        return res.id;
    },

    //关于系统页面数据
    aboutInfo:async (params)=>{
        return await utils.$$post({ url: "/utils/aboutInfo", params: params });
    },
    //其实这里可以不用async await，因为在调用的时候用的async await，所以这里只需要是一个promise即可。
    //以后万一这里需要用到同步呢，所以还是写上吧。
    //机构数据
    corpTreeData:async (params)=>{
        let res = await utils.$$post({ url: "/corp/tree", params: params });
        return res.data;
    },
    //机构列表树
    corpListTreeData:async (params)=>{
        return await utils.$$post({ url: "/corp/corpListTreeData", params: params });
    },

    //角色数据
    roleData:async (params)=>{
        params = Object.assign({}, {type:'roleSelectData'}, params);
        let res = await utils.$$post({ url: "/role/custom", params: params });
        return JSON.parse(res.roleSelectData);
    },
    //----------------登录----------------
    //登录初始化数据
    loginInitData:async ()=>{
        return await utils.$$post({ url: "/user/loginInit"});
    },
    //用户登录
    login:async (params)=>{
        return await utils.$$post({ url: "/user/login", params: params });
    },
    //退出登录
    loginOut:async (params)=>{
        return await utils.$$post({ url: "/user/loginOut", params: params });
    },
    //加载个人信息请求
    personalInfos:async (params)=>{
        return await utils.$$post({ url: "/changePersonalInfos/personalInfos", params: params });
    },
    //修改密码
    changePassword:async (params)=>{
        return await utils.$$post({ url: "/user/changePassword/changePassword", params: params });
    },
    //修改个人其它信息
    changeOtherInfo:async (params)=>{
        return await utils.$$post({ url: "/user/changeOtherInfo", params: params });
    },
    //加载用户菜单
    loadUserMenus:async (params)=>{
        return await utils.$$post({ url: "/roleMenu/userMenus", params: params });
    },
    //加载指定角色菜单
    loadRoleMenu:async (params)=>{
        return await utils.$$post({ url: "/roleMenu/load", params: params });
    },
    //保存角色菜单
    saveRoleMenu:async (params)=>{
        return await utils.$$post({ url: "/roleMenu/save", params: params });
    },

    //构建树形系统数据库表
    tableTreeData:async (params)=>{
        return await utils.$$post({ url: "/genCode/tree", params: params });
    },

    //消息custom请求
    messageCustom:async (params)=>{
        return await utils.$$post({ url: "/message/custom", params: params });
    },
    //生成代码
    genCode:async (params)=>{
        let res = await utils.$$post({ url: "/genCode/genCode", params: params });
        return res;
    },
    deleteFile:async (params)=>{
        const aloneServerInfo=utils.$$str.decrypt(sessionStorage.getItem("aloneServerInfo"));
        let result=await utils.$$post({ url: "/deleteFile", urlPre: aloneServerInfo, params: params});
        return result;
    },
    //------------------------------customer400----------------------------------
    getCustomerAddress:async (params)=>{
        let result=await utils.$$post({ url: "/customer/getCustomerAddress", params: params});
        let data=[];
        result.resultData.forEach((item,index)=>{
            data.push({value:item.id,label:item.address,item:item});
        })
        return data;
    },
    getPersons:async (params)=>{
        let result=await utils.$$post({ url: "/person/getPersons", params: params});
        return utils.$$wjdcUtils.dealSelectData(result.resultData);
    },
    getRoles:async (params)=>{
        let result=await utils.$$post({ url: "/role/getRoles", params: params});
        return utils.$$wjdcUtils.dealSelectData(result.resultData);
    },
}