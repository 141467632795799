<template>
    <div :class="loginClass" :style="{background: 'url('+cssVars.backGroundPng+')','background-size': '100% 100%'}">
        <el-form :model="loginForm" :rules="loginFormRules" ref="loginFormRef" class="loginForm">
            <el-form-item>
                <h2 class="title">{{loginMsg}}</h2>
            </el-form-item>

            <el-form-item prop="usercode">
                <el-input ref='usercodeRef' type="text" placeholder="请输入用户名" @input="e => loginForm.usercode = validForbid(e)" v-model="loginForm.usercode" suffix-icon="el-icon-s-custom" autocomplete="off" clearable autofocus="true" @keyup.enter.native="doNext('usercode')"/>
            </el-form-item>

            <el-form-item prop="password">
                <el-input ref='passwordRef' type="password" placeholder="请输入密码" @input="e => loginForm.password = validForbid(e)" v-model="loginForm.password" suffix-icon="el-icon-view" autocomplete="off" clearable @keyup.enter.native="doNext('password')"/>
            </el-form-item>

            <el-form-item prop="verifyCode"  v-if="useYzm">
                <div style="display: flex;flex-direction: row;justify-content: space-between;" v-if="yzmType=='1'">
                    <div style="flex: 3">
                        <el-input ref='verifyCodeRef' type="text" placeholder="请输入右侧验证码" @input="e => loginForm.verifyCode = validForbid(e)" v-model="loginForm.verifyCode" autocomplete="off" clearable @keyup.enter.native="doNext('verifyCode')"/>
                    </div>
                    <div style="flex: 1;margin-left: 5px;">
                        <VerfiCode ref="verifyCodeComRef"></VerfiCode>
                    </div>
                </div>
                <Silder status="silderStatus" ref="sliderRef" :successFun='sliderSuccess' :errorFun='sliderError' v-if="yzmType=='0'"/>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="submitForm()" class="loginBtn">登录</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    const config = require('./utils/config.js');
    //数字验证码
    import VerfiCode from "@/components/utils/VerfiCode";
    //滑块验证码
    import Silder from "@/components/utils/Silder";
    import { ref,computed,getCurrentInstance,reactive,toRefs,onMounted,defineComponent} from 'vue';
    export default defineComponent({
        name: "Login",
        setup(props,context){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;

            const loginFormRef=ref(null);
            const usercodeRef=ref(null);
            const passwordRef=ref(null);
            const verifyCodeRef=ref(null);
            const verifyCodeComRef=ref(null);
            const sliderRef=ref(null);
            let store = proxy.$store;


            let dataObj=reactive({
                silderStatus: false,
                yzmType:'0',
                backgroundImage:'',
                useYzm: true,
                loginForm: {
                    usercode: "",
                    password: "",
                    clientType:0,
                    verifyCode: ""
                },
                loginFormRules: {
                    usercode: [{required: true, message: "请输入用户名", trigger: "blur"}],
                    password: [{required: true, message: "请输入密码", trigger: "blur"}]
                },
                fullscreenLoading:false,
                loginMsg:config.title,
                cssVars:{
                    backGroundPng: config.title=='老公货嘀运营管理平台'?require('./assets/picResources/business.png'):require('./assets/picResources/sass.png')
                }
            })

            onMounted(async ()=>{
                sessionStorage.clear();
                store.dispatch("closeAllVisitedTabsView");
                usercodeRef.value.focus();
            })
            const checkBrows=()=>{
                let userAgent=navigator.userAgent;
                if(userAgent.indexOf("Chrome")==-1){
                    proxy.$notify({
                        title: '提示',
                        type: 'warning',
                        dangerouslyUseHTMLString: true,
                        position: 'top-left',
                        message: '为了能够正常使用系统，请使用<span style="color: red;">谷歌</span>浏览器',
                        duration: 0
                    });
                }
            }
            checkBrows();
            const sliderSuccess=()=>{
                dataObj.silderStatus=true;
            }
            const sliderError=()=>{}
            const doLogin=async()=> {
                if(dataObj.useYzm && dataObj.yzmType=='1'){
                    let isVerfiCodeRight=verifyCodeComRef.value.verfiCode(dataObj.loginForm.verifyCode);
                    if(!isVerfiCodeRight){
                        dataObj.fullscreenLoading=false;
                        utils.$$tools.info({message: "验证码不正确"});
                        return ;
                    }
                }else if(dataObj.useYzm && dataObj.yzmType=='0' && !dataObj.silderStatus){
                    dataObj.fullscreenLoading=false;
                    utils.$$tools.info({message: "请拖动滑块到最右边"});
                    return;
                }
                dataObj.loginForm.password = utils.$$str.encrypt(dataObj.loginForm.password);
                try {
                    let res = await utils.$$api.login(dataObj.loginForm);
                    if(res[utils.$$const.baseVar.errorCode]==utils.$$const.sysCode._0000){
                        store.dispatch("configEncryptData", res.encryptData);//设置是否加密传输数据到vuex
                        store.dispatch("configChangePersonalInfoPermission", res.hasChangePersonalInfosPermission);

                        sessionStorage.setItem("userMenus", utils.$$str.encrypt(res.userMenus));
                        sessionStorage.setItem("welcomeMsg", res.welcomeMsg);

                        dataObj.loginForm.password = '';
                        dataObj.loginForm.hasEditPortal=res.hasEditPortal;
                        dataObj.loginForm.userName=res.userName;

                        sessionStorage.setItem("userInfo",utils.$$str.encrypt(JSON.stringify(dataObj.loginForm)));
                        sessionStorage.setItem(utils.$$const.baseVar.jfAccessToken, res[utils.$$const.baseVar.jfAccessToken]);

                        sessionStorage.setItem("aloneServerInfo",utils.$$str.encrypt(res.aloneServerInfo));
                        sessionStorage.setItem('mainMsg',res.mainMsg);


                        dataObj.fullscreenLoading=false;
                        proxy.$router.push('/');
                        utils.$$tools.success({message: "登录成功"});
                    }else{//后台验证失败
                        dataObj.loginForm.password = "";
                        utils.$$tools.info({message: res.msg});
                        if(dataObj.useYzm && dataObj.yzmType=='1'){
                            verifyCodeComRef.value.createCode();
                            dataObj.loginForm.verifyCode = "";
                        }else if(dataObj.useYzm && dataObj.yzmType=='0'){
                            dataObj.silderStatus=false;
                            sliderRef.value.resetSlider();
                        }
                    }
                    if(dataObj.fullscreenLoading==true)dataObj.fullscreenLoading=false;
                } catch (err) {
                    dataObj.fullscreenLoading=false;
                    usercodeRef.value.focus();
                }
            }
            const submitForm=()=>{
                loginFormRef.value.validate(valid => {
                    if (valid) {
                        dataObj.fullscreenLoading=true;
                        doLogin();
                    } else {
                        proxy.$message.info("请填写完登录信息");
                        return false;
                    }
                });
            }
            const doNext=(field)=> {
                switch (field) {
                    case "usercode":
                        passwordRef.value.focus();
                        break;
                    case "password":
                        if (dataObj.useYzm && dataObj.yzmType=='1') {
                            verifyCodeRef.value.focus();
                        } else {
                            submitForm();
                        }
                        break;
                    case "verifyCode":
                        submitForm();
                        break;
                }
            }
            const loginClass=computed(()=>{
                return {
                    login: true
                };
            })
            return {
                ...toRefs(dataObj),loginFormRef,usercodeRef,passwordRef,verifyCodeRef,sliderRef,verifyCodeComRef,submitForm,doNext,doLogin,sliderSuccess,sliderError,loginClass
            }
        },
        components: {VerfiCode,Silder}
    });
</script>

<style scoped>
    /*登录动画1 */
    .login {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /*background: url("./assets/picResources/business.png");*/
        /*background-size: 100% 100%;*/
    }

    .loginForm {
        opacity: 1;
        width: 350px;
        padding: 40px 40px 40px 40px;
        border-radius: 10px;
        border: 1px solid #eaeaea;
        /*box-shadow: 0 0 25px #cac6c6;*/
        /*表单默认是透明的，让其变为不透明*/
        background-color: rgba(255, 255, 255,1);
    }

    .loginBtn {
        width: 100%;
    }

    .title {
        width: 100%;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        /*color: darkcyan;*/
        /*border-bottom: 1px solid #ddd;*/
    }
</style>