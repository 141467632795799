<template>
    <div class="content-container" id="content">
        <!--显示外部链接-->
        <iframe :src="outerLink" v-if="isOuterLink"  width="100%" height="100%" frameborder="0" scrolling="auto" id="outerLink"/>
        <!--
            二级路由
            如果在以下router-view里面加上:key="route.path"，那么动画就不会生效，不知道怎么回事
        -->
        <router-view v-slot="{Component}" v-if="isRouterAlive && !isOuterLink" ref="menuRouter">
<!--            <transition :name="animationName">-->
<!--                <keep-alive>-->
<!--                    <component :is="Component" ref="compRef"/>-->
<!--                </keep-alive>-->
<!--            </transition>-->

            <keep-alive>
                <component :is="Component" ref="compRef"/>
            </keep-alive>
        </router-view>
        <!--portal-->
        <div :class="{'outerDiv':curRoute=='welcome','outerDiv2':curRoute!='welcome'}">
            <div class="portalConfig">
                <div class="toolLeft" v-if="curRoute=='welcome'">{{welcomeMsg }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref,reactive,toRefs,onMounted,computed,watch,getCurrentInstance,nextTick,defineComponent} from 'vue';

    import { useRoute  } from "vue-router";
    export default defineComponent({
        name: "Content",
        setup(){
            const route = useRoute();
            // console.log(route)
            const portalTableRef=ref(null);
            const menuRouterRef=ref(null);
            const {proxy} = getCurrentInstance();
            const utils=proxy.utils;

            let dataObj=reactive({
                compRef:null,
                outerLink:'',
                isOuterLink:false,
                welcomeMsg:sessionStorage.getItem("welcomeMsg"),
                curRoute:'',
                isRouterAlive: true,
                animationName:!localStorage.getItem('routeAnimation')?'el-zoom-in-center':localStorage.getItem('routeAnimation')
            })

            onMounted(async ()=>{
                dataObj.curRoute=route.name;
            })
            watch(() => route.path,() => {
                dataObj.curRoute=route.name;
                    let menuMeta=route.meta;
                    if(menuMeta.page && menuMeta.page.startsWith("http")){
                        dataObj.isOuterLink=true;
                        dataObj.outerLink=route.meta.page;
                    }else{
                        dataObj.isOuterLink=false;
                        dataObj.outerLink='';
                    }
                }
            )

            const reload=()=> {
                let menuMeta=route.meta;
                if(menuMeta.page && menuMeta.page.startsWith("http")){
                    document.getElementById('outerLink').src = dataObj.outerLink;
                }else{
                    dataObj.isRouterAlive = false;
                    nextTick(()=>{
                        dataObj.isRouterAlive = true;
                    })
                }
            }

            return{
                ...toRefs(dataObj),reload, portalTableRef,menuRouterRef,route
            }
        },
    });
</script>

<style scoped>
    .content-container {
        width: 100%;

        height: calc(100% - 40px);
        padding: 0px 0px 0px 5px;
    }
    .toolLeft{
        flex: 1;
        font-size: 14px;
        color:  #295581;
    }
    .toolLeft:hover{
        color:  #EE6600;
    }
    .portalConfig {
        width: 100%;
        height: 30px;
        margin: 4px 0px;
        border-bottom: solid 1px darkcyan;
        display: flex;
        flex-direction: row;
    }
    .outerDiv {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: url("../../assets/picResources/index_business.png") no-repeat center center;
        background-size:645px 370px;
    }
    .outerDiv2{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
</style>