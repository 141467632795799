import {ref, reactive, toRefs, onMounted, computed, getCurrentInstance, provide, nextTick, defineComponent, markRaw, createApp} from 'vue'
const DialogHelper = defineComponent({
    name: "Dialog",
    setup(props,context){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        provide('dialogInst', proxy);
        let modelEngineParams=proxy.$params;
        let dataObj=reactive({
            customClass:modelEngineParams.dialogDiv+"_class",
            modelEngineRef:null,
            modelEngine:null,
            modelEngineParams:modelEngineParams,
            fullscreenLoading: false,
            dialogOptions: {
                title: "提示",
                dWidth: "60%",
                contentHeight: '',
                showFooterBtn: false,
                fullscreen: true,
                closeModel: false,
                closeDestroy: true
            },
            dialogVisible: false,
        })

        onMounted(()=>{
            let fixProp={title:(modelEngineParams.title?modelEngineParams.title:'弹窗'),fullscreen:(modelEngineParams.fullscreen?modelEngineParams.fullscreen:false)};
            if(modelEngineParams.modelComp && modelEngineParams.modelComp.title){
                fixProp.title=modelEngineParams.modelComp.title;
            }
            if(modelEngineParams.modelComp && modelEngineParams.modelComp.fullscreen){
                fixProp.fullscreen=modelEngineParams.modelComp.fullscreen;
            }
            dataObj.dialogOptions = Object.assign({}, dataObj.dialogOptions,modelEngineParams,fixProp);//合并处理后的参数

            let modelType=modelEngineParams.modelType?modelEngineParams.modelType:modelEngineParams.modelComp.modelType;
            if(!modelType || modelType=='list'){
                dataObj.modelEngine=modelEngineParams.modelComp;
                if(!dataObj.dialogOptions.contentHeight){
                    dataObj.dialogOptions.contentHeight='620px';
                    // dataObj.dialogOptions.contentHeight='520px';
                }
            }else{
                if('card'===modelType){
                    import("@/components/base_comp/CardEngine").then(component=>{
                        dataObj.modelEngine=component.default;
                    });
                } if('link'===modelType){
                    import("@/components/base_comp/LinkComponent").then(component=>{
                        dataObj.modelEngine=component.default;
                    });
                }
            }
        })
        const buttomHandler=(operType)=>{
            if (modelEngineParams.ownerComp && modelEngineParams.ownerComp[operType]){
                let aa=modelEngineParams.ownerComp[operType](dataObj.modelEngineRef);
                aa.then(res=>{
                    if(res){
                        dataObj.dialogVisible=false;
                    }
                })
            }else{
                dataObj.dialogVisible=false;
            }
        }
        const doClose=()=>{
            document.body.removeChild(document.getElementById(modelEngineParams.dialogDiv));
            let divs=document.getElementsByClassName(modelEngineParams.dialogDiv+"_class");
            for(let i=0;i<divs.length;i++){
                if(divs[i].parentNode)document.body.removeChild(divs[i].parentNode);
            }
            if (modelEngineParams.ownerComp && modelEngineParams.ownerComp['cancelHandler']){
                modelEngineParams.ownerComp['cancelHandler'](dataObj.modelEngineRef)
            }
        }
        const close=()=>{}
        const closed=()=>{doClose();}
        const open=()=>{}
        const opened=async()=> {}
        return{
            ...toRefs(dataObj),doClose,close,closed,open,opened,buttomHandler
        }
    },
    components: {}
});
export default DialogHelper;