<template>
    <div id="pageList" class="corpList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">编码：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.code = validForbid(e)" placeholder="请输入编码模糊匹配查询..." v-model="pageList.queryParam.code" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">名称：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.name = validForbid(e)" placeholder="请输入名称模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                </el-row>
            </template>

            <template v-slot:tbCols>
<!--                <el-table-column prop="F_CODE" label="编码" sortable='true' :formatter="formatShow" align="left" headerAlign="left"/>-->
<!--                <el-table-column prop="F_NAME" label="名称" sortable='custom' :formatter="formatShow" align="left" headerAlign="left"/>-->
                <el-table-column prop="F_NAME" label="名称" headerAlign="left" align="left"/>
                <el-table-column prop="F_CODE" label="编码" headerAlign="left" align="left"/>

                <el-table-column prop="F_PARENT_NAME" label="上级名称"/>
                <el-table-column prop="F_ADDR" label="地址"/>
                <el-table-column prop="F_TEL" label="电话"/>
                <el-table-column prop="F_CONTACTS" label="联系人"/>
            </template>
        </ListEngine>
    </div>

</template>

<script>
    import CorpCard from './CorpCard';
    import {ref, reactive, toRefs, onMounted, defineComponent, getCurrentInstance, provide} from 'vue';
    export default defineComponent ({
        name: "corpList",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                pageList: {
                    isShowFixCol:false,
                    isShowBtnGroup:false,
                    isShowQueryParam:false,
                    rowKey:'id',

                    // queryParam: {
                    //     code: '',
                    //     name: ''
                    // },
                    // modelComp: CorpCard,
                    modelMethod: "/corp/pageData"
                }
            })
            const formatShow=(row, column, value, index)=>{
                let npbspNum=row.F_LEVEL*2;
                let pre="";
                for(let i=0;i<npbspNum;i++){
                    pre=pre+"\u3000"
                }
                return pre+value;
            }
            onMounted(()=>{

            })
            const afterPageData=async (res1)=>{
                const loading = proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                let res = await utils.$$api.corpListTreeData({});
                let data=JSON.parse(res.treeData);
                dataObj.pageListRef.getTbInst().setTbData(data);
                loading.close();
            }
            return{
                ...toRefs(dataObj),formatShow,afterPageData
            }
        }
    });
</script>

<style scoped>
.corpList{width: 100%;}
</style> 
