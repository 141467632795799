
const ip_local='192.168.121.21';//本机
const ip_test='192.168.7.131';//测试
const ip_product='192.168.7.131';//生产

// const type='local';
// const type='test';
const type='product';

const title='400客户档案查询平台';

let config = {
    contextPath:'/customer400',
    title:title,
    baseURL:type=='local'?('http://'+ip_local+':7788')
        :(
            type=='test'?
                ('http://'+ip_test+':8006')
                :
                 //('http://192.168.8.73')
                //('https://400.shinerayapp.com')
                 ('https://400.suxiangkj.com')
        ),
    bindAppProps:(app,props)=>{
        props.forEach(item=>{
            app.config.globalProperties[item.key] = item.value;
        });
    }
}
module.exports=config;