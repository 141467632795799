import {ref, reactive, toRefs, computed,watch,defineComponent, onMounted, getCurrentInstance,provide} from 'vue';
const WjCardHelper = defineComponent({
    name:'WjCard',
    title:'问卷信息',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        provide('uploadOwner', proxy);
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            refMap:new Map(),
            importDataDialogVisible:false,
            uploadParams:{
                limitNum:5000,
                action:'/customer/uploadFiles',
            },
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: true,
                details:['wjContent'],
                modelPath: "/customer"
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                name: [
                    {required: true, message: "请输入名称", trigger: "blur" }
                ],
                time: [
                    {required: true, message: "请选择调研时间", trigger: "blur" }
                ],
            }
        })
        onMounted(()=>{
        })
        //---------------------------computed---------------------------
        //明细表格初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType) => {
                return {
                    detailParam: {
                        title:'问卷调查内容',
                        readOnly:true,
                        showToolBar:false,
                        canPage: false,
                        queryParam: {
                            detailType: detailType
                        },
                        modelMethod: "/customer/detail?t="+Math.random()*1000
                    }
                }
            }
        })
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(data,addOrLoad,cardEngine)=>{
        }
        //行开始编辑事件回调方法，点击某一行编辑或新增一行都会调用该方法。区别：新增行的时候不会传入options,options为undifine，编辑的时候options为{row: row, column: column}
        const beganEdit=async(detailType,options)=>{
            //todo:如果明细中有cascader，而且每行的cascader数据可能不同，那么可以在这里再次请求后台，重新根据条件构造该行cascader数据
            return true;
        }
        //结束编辑行的时候，为该行指定列设置显示文本，方便不是编辑状态的情况下显示
        const beforeEndEdit=(detailType, row, index)=>{
            if("wjContent"==detailType){
            }
            return true;
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        //下载文件
        const downLoad=async ()=>{
            let sourceName="wjTemplate.xlsx";
            let res = await utils.$$downLoad({cluserServer:1,url:'/customer/downLoadResourceFile', params: {sourceName:sourceName,fileName:'问卷导入模版.xlsx'}});
        }
        //上传的时候，增加参数传入到后台
        const buildUploadParams=(formData,params,proxy)=>{
            formData.append('wjdcId',dataObj.form.id);
        }
        //上传完成之后
        const afterResult=(res)=>{
            if(res.result){
                dataObj.refMap.get('wjContent').reloadGrid();
            }
        }
        //导入
        const importHandler=()=>{
            dataObj.importDataDialogVisible=true;
        }
        //导出
        const exportExcelHandler=()=>{
            let tbData=dataObj.refMap.get('wjContent').getDetailData();
            if(tbData.length>0){
                utils.$$tools.configBox({
                    msgContent:'确定执行该操作吗?',
                    fn:async ()=> {
                        const loading = proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                        await exportExcel(tbData);
                        loading.close();
                    }
                });
            }else{
                proxy.$message("没有内容可以导出");
            }
        }
        const formatJson=(fields, jsonData)=>{
            return jsonData.map(rowData => fields.map(field => rowData[field]))
        }
        const exportExcel=(tbData)=>{
            return new Promise((resolve, reject) => {
                let fields=dataObj.refMap.get('wjContent').getTbInst().tbCols.fields;
                let labels=dataObj.refMap.get('wjContent').getTbInst().tbCols.labels;
                const data = formatJson(fields, tbData);//处理之后的tbData
                proxy.excelUtils(labels, data, dataObj.form.name + "问卷内容");
                resolve();
            }).catch(err => {
                console.log('导出excel出错了')
            });
        }
        return{
            ...toRefs(dataObj),cardDetailParam,beganEdit,beforeEndEdit,beforeOpen,beforeSaveHandler,buildUploadParams,afterResult,importHandler,exportExcelHandler,downLoad
        }
    }
});
export default WjCardHelper;