import { ref,computed,getCurrentInstance,reactive,toRefs,onMounted,defineComponent} from 'vue';

const UserCardHelper = defineComponent({
    name: "userCard",
    title: "账号管理",
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            refMap:new Map(),
            compParams: {
                modelPath: "/user"
            },
            parentTreeData: [],
            personData: [],
            roleData: [],
            form: {
                code: "",
                name: "",
                personId: "",
                roleId: "",
                roleText: "",
                desc: ""
            },
            rules: {
                code: [
                    { required: true, message: "请输入帐号", trigger: "blur" }
                ],
                name: [
                    { required: true, message: "请输入名称", trigger: "blur" }
                ],
                personId: [
                    { required: true, message: "请选择人员", trigger: "blur" }
                ]
            }
        });

        onMounted(async ()=>{
            await buildPersonData();
            await buildRoleData();
        })
        const beforeOpen=async(data,addOrLoad,cardEngine)=>{
            if('/load'==addOrLoad)dataObj.form.roleId=JSON.parse(data.data.roleId);
        }
        //下拉选择
        const comboSelect=computed(()=>{
            return (params) => {
                if(params && 'personId'==params.comboId){
                    return dataObj.personData;
                }
                if(params && 'roleId'==params.comboId){
                    return dataObj.roleData;
                }
            }
        })
        //---------------------------computed------------
        const buildPersonData=async ()=>{
            dataObj.personData= await utils.$$api.getPersons({});
        }
        const buildRoleData=async ()=>{
            dataObj.roleData= await utils.$$api.getRoles({});
        }

        const changeStatus=async(params)=>{
            utils.$$tools.configBox({
                msgContent:'确定要执行该操作吗?',
                fn:async ()=> {
                    let url = dataObj.compParams.modelPath + "/custom";
                    params= JSON.stringify(params)
                    let res = await utils.$$api.postRequest({ url: url, params: params});
                    utils.$$tools.success({ message: res.msg });
                    if (proxy.engine.engineParams.ownerComp && proxy.engine.engineParams.ownerComp.queryHandler) proxy.engine.engineParams.ownerComp.queryHandler();
                    await proxy.engine.doAddOrLoad(proxy.engine.id);
                }
            });
        }
        const startHandler=async()=>{
            let params={id: dataObj.form.id,type:'start',operateType:'changeUserStatus'}
            await changeStatus(params);
        }
        const stopHandler=async()=>{
            let params={id: dataObj.form.id,type:'stop',operateType:'changeUserStatus'}
            await changeStatus(params);
        }
        const resetPwdHandler=async()=>{
            utils.$$tools.configBox({
                msgContent:'确定要执行该操作吗?',
                fn:async ()=> {
                    let url = dataObj.compParams.modelPath + "/custom";
                    let params={id:dataObj.form.id,operateType:'resetPwd'}
                    let res = await utils.$$api.postRequest({ url: url, params: params});
                    utils.$$tools.success({ message: res.msg });
                }
            });
        }

        return{
            ...toRefs(dataObj),beforeOpen,comboSelect,changeStatus,startHandler,stopHandler,resetPwdHandler
        }
    }
});
export default UserCardHelper;