const SETPARAM="setParam";
const ADDORLOAD = "addOrLoad";
const BEFOREOPEN = "beforeOpen";
const BEFORESAVEHANDLER = "beforeSaveHandler";
const AFTERSAVEHANDLER = "afterSaveHandler";
const SAVEHANDLER = "saveHandler";
const RESETHANDLER = "resetHandler";
const SETDETAILSJSON = "setDetailsJson";
const LOADDETAILS="loadDetails";
const BEFOREDELETEHANDLER = "beforeDeleteHandler";
const AFTERDELETEHANDLER = "afterDeleteHandler";
import {ref, reactive, toRefs, onBeforeMount,onMounted, computed, getCurrentInstance, provide, inject, nextTick, defineComponent} from 'vue'

const CardEngineHelper = defineComponent({
    name: 'CardEngine',
    props: {
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        let dialogInst = inject('dialogInst');
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        const engineParams=props.engineParams;
        // console.log(engineParams);
        let dataObj=reactive({
            approveLog:false,
            reverse:true,
            approveLogContentHeight:400,
            logInfoArr:[],

            dialogInst:dialogInst,
            modelCompRef:null,
            id:'',
            mainMaxHeight:400,
            loading:false,
            buttons:[],
            modelComp:engineParams.modelComp,
            modelInst:null
        })
        onMounted(async()=>{
            dataObj.loading=true;//打开正在加载效果
            dataObj.approveLogContentHeight=(window.innerHeight-141);
            dataObj.mainMaxHeight=(window.innerHeight-64);
            dataObj.modelInst=dataObj.modelCompRef;
            dataObj.modelInst.engine=proxy;
            await doAddOrLoad(engineParams.id);
        })
        const clickHandler=(ev)=>{
            if (dataObj.modelInst[ev]) {
                dataObj.modelInst[ev](proxy);
            } else {
                proxy[ev]();
            }
        }
        const doAddOrLoad=async(id)=>{
            if(!dataObj.loading)dataObj.loading=true;
            if(dataObj.modelInst[ADDORLOAD]){
                await dataObj.modelInst[ADDORLOAD](proxy);
            }else{
                let addOrLoad = "/add";
                if(utils.$$str.isNotEmpty(id)){
                    addOrLoad = "/load";
                }
                let url = dataObj.modelInst.compParams.modelPath + addOrLoad;
                let params={};
                if (dataObj.modelInst[SETPARAM]){
                    params=await dataObj.modelInst[SETPARAM](proxy);
                }
                params=Object.assign({}, {id: id,modelType:'card'}, params);
                dataObj.id=id;
                let res = await utils.$$api.postRequest({url: url, params: params});
                if(res.result){
                    if(res.data)dataObj.modelInst.form = res.data;
                    dataObj.buttons = res.buttons;
                }
                if (dataObj.modelInst[BEFOREOPEN]) await dataObj.modelInst[BEFOREOPEN](res, addOrLoad,proxy);
                if (dataObj.modelInst.compParams.hsDetails && dataObj.modelInst.compParams.details){
                    let details=dataObj.modelInst.compParams.details;
                    for (const detailType of details) {
                        if(dataObj.modelInst[LOADDETAILS]){
                            await dataObj.modelInst[LOADDETAILS](detailType,id,proxy);
                        }else{
                            await dataObj.modelInst.refMap.get(detailType).reloadGrid({detailType:detailType,mainId:id});
                        }
                    }
                }
                if (dataObj.modelInst['afterOpened']) await dataObj.modelInst['afterOpened'](res, addOrLoad,proxy);
            }
            dataObj.loading=false;
        }
        const doSave=async(noteFlag)=>{
            dataObj.loading=true;
            if (dataObj.modelInst.compParams.hsDetails) {
                if(dataObj.modelInst[SETDETAILSJSON]){
                    await dataObj.modelInst[SETDETAILSJSON](proxy);
                }else{
                    let details=dataObj.modelInst.compParams.details;
                    for (const detailType of details) {
                        let detailData=await dataObj.modelInst.refMap.get(detailType).getSaveDetailData();
                        if(detailData){
                            dataObj.modelInst.form[detailType]=detailData;
                        }else{
                            dataObj.loading=false;
                            return;
                        }
                    }
                }
            }
            if (dataObj.modelInst[BEFORESAVEHANDLER] && !dataObj.modelInst[BEFORESAVEHANDLER](engineParams.contentName,proxy)) {
                dataObj.loading=false;
                return;
            }
            if(dataObj.modelInst.compParams.saveConfirm){
                utils.$$tools.configBox({
                    msgContent:dataObj.modelInst.compParams.saveConfirmContent,
                    fn:async ()=> {
                        await ddSave(noteFlag);
                    },
                    cancelFn:()=>{
                        dataObj.loading=false;
                    }
                });
            }else{
                await ddSave(noteFlag);
            }

        }
        const ddSave=async (noteFlag)=>{
            let url = dataObj.modelInst.compParams.modelPath + "/save";
            let params = JSON.stringify(dataObj.modelInst.form);
            try {
                let res = await utils.$$api.postRequest({url: url, params: params});
                if(res.result){
                    if(noteFlag)utils.$$tools.success({message: res.msg});
                    await doAddOrLoad(res.data.id);
                    if (dataObj.modelInst.compParams.hsDetails && dataObj.modelInst.compParams.details){
                        let details=dataObj.modelInst.compParams.details;
                        for (const detailType of details) {
                            if(dataObj.modelInst[LOADDETAILS]){
                                await dataObj.modelInst[LOADDETAILS](detailType,res.data.id,proxy);
                            }else{
                                await dataObj.modelInst.refMap.get(detailType).reloadGrid({detailType:detailType,mainId:res.data.id});
                            }
                        }
                    }
                    if (dataObj.modelInst[AFTERSAVEHANDLER] && !dataObj.modelInst[AFTERSAVEHANDLER](res,engineParams.contentName,proxy)){
                        dataObj.loading=false;
                        return;
                    }
                    if (engineParams.ownerComp && engineParams.ownerComp.queryHandler) engineParams.ownerComp.queryHandler();
                }else{
                    if (dataObj.modelInst['saveFail']){
                        await dataObj.modelInst['saveFail'](res,engineParams.contentName,proxy)
                    }
                    dataObj.loading=false;
                }
            } catch (e) {
                dataObj.loading=false;
            }
        }
        const saveHandler=()=>{
            if (dataObj.modelInst[SAVEHANDLER]) {
                dataObj.modelInst[SAVEHANDLER](proxy);
            } else {
                if (dataObj.modelInst['beforeValidate']){
                    if(!dataObj.modelInst['beforeValidate'](engineParams.contentName,proxy))return;
                }
                dataObj.modelInst.formRef.validate(valid => {
                    valid ? doSave(true) : false;
                });
            }
        }
        const resetHandler=async()=>{
            if (dataObj.modelInst[RESETHANDLER]) {
                dataObj.modelInst[RESETHANDLER](engineParams.contentName,proxy);
            } else {
                if (utils.$$str.isNotEmpty(dataObj.modelInst.form.id)) {
                    await doAddOrLoad(dataObj.modelInst.form.id);
                } else {
                    dataObj.modelInst.formRef.resetFields(proxy);
                    if (dataObj.modelInst.compParams.hsDetails && dataObj.modelInst.compParams.details){
                        let details=dataObj.modelInst.compParams.details;
                        for (const detailType of details) {
                            await dataObj.modelInst.refMap.get(detailType).clearDetailData();
                        }
                    }
                }
                utils.$$tools.success({message: "操作成功!"});
            }
        }
        //删除，向后台发送请求
        const doDeleteHandler=async()=>{
            //打开正在加载效果
            dataObj.loading=true;
            if (dataObj.modelInst[BEFOREDELETEHANDLER] && !dataObj.modelInst[BEFOREDELETEHANDLER](dataObj.id,proxy)) {
                dataObj.loading=false;
                return;
            }
            let url = dataObj.modelInst.compParams.modelPath + '/delete';
            let res = await utils.$$api.postRequest({url: url, params: {id: dataObj.id}});
            //关闭正在加载效果
            dataObj.loading=false;
            if(res.result){
                utils.$$tools.success({message: res.msg});
                if (dataObj.modelInst[AFTERDELETEHANDLER]) dataObj.modelInst[AFTERDELETEHANDLER](dataObj.id,proxy)
                if (engineParams.ownerComp && engineParams.ownerComp.queryHandler) engineParams.ownerComp.queryHandler();
                dialogInst.dialogVisible=false;
            }
        }
        //删除事件
        const delHandler=()=>{
            if(utils.$$str.isNotEmpty(dataObj.id)){
                utils.$$tools.configBox({
                    msgContent:'确定删除该条记录吗？',
                    fn:async ()=>{
                        await doDeleteHandler();
                    }
                });
            }else{
                proxy.$message('新增状态，不能删除！');
            }
        }
        //审批日志
        const verifyHisHandler=async ()=>{
            let url = '/verifyHis/custom';
            let res = await utils.$$api.postRequest({ url: url, params: {id:dataObj.id,operateType:'approveLog'}});
            // console.log(res);
            dataObj.logInfoArr=res.resultData;
            dataObj.approveLog=true;
        }
        return{
            ...toRefs(dataObj),clickHandler,doAddOrLoad,doSave,saveHandler,resetHandler,doDeleteHandler,delHandler,verifyHisHandler
        }
    }
});
export default CardEngineHelper;