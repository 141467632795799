<template>
    <div ref="welcomeRef" class="welcomeMin">
        <div class="test"></div>
    </div>
</template>

<script>
    import { ref,computed,getCurrentInstance,reactive,toRefs,onMounted} from 'vue';
    export default {
        name: "Welcome",
        setup(){
            const welcomeRef=ref(null);
            const appInstant = getCurrentInstance();
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            const dataObj=reactive({

            })
            return{
                ...toRefs(dataObj),welcomeRef
            }
        }
    };
</script>

<style scoped>
    .welcomeMin {
    }
    .test{
        width: 0px;
        height: 0px;
    }
</style>
