import {ref, reactive, toRefs, onMounted, defineComponent, getCurrentInstance, computed} from 'vue';
const RoleMenuHelper = defineComponent({
    name: "RoleMenu",
    title: "角色菜单管理",
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        let dataObj=reactive({
            treeRef:null,
            compParams: {
                modelPath:'/roleMenu'
            },
            defaultProps: {
                children: "children",
                label: "title"
            },
            topMenuTree: {},
            activeMenuId: "",
            treeData: [],

            menuSelPermissionIterms: {},
            menuSelKeys: [],
            roleId: '',
            enginInst:{},
            form: {}
        })
        const addOrLoad=async(enginInst)=>{
            let res = await utils.$$api.loadRoleMenu({ roleId: enginInst.engineParams.id,modelType:'card'});
            enginInst.buttons=res.buttons;
            let allMenus = JSON.parse(res.allMenus);
            buildTopMenuTree(allMenus);
            dataObj.menuSelPermissionIterms = res.mapMenuPermissions;
            dataObj.menuSelKeys = res.selMenus;
            dataObj.treeRef.setCheckedKeys(res.selMenus);
            dataObj.roleId = enginInst.engineParams.id;
            dataObj.enginInst=enginInst;
        }

        const buildTopMenuTree=(allMenus)=>{
            let topMenuTree = {
                topMenus: [],
                trees: []
            };
            for (let i = 0; i < allMenus.length; i++) {
                let menu = {};
                let menuIterm = allMenus[i];

                menuIterm.menuItermPermission =allMenus[i].menuItermPermission;
                menu.menuId = menuIterm.id;
                menu.title = menuIterm.title;
                topMenuTree.topMenus.push(menu);

                let tree = {};
                tree.parentId = menuIterm.id;
                tree.treeData = menuIterm;
                topMenuTree.trees.push(tree);
            }
            dataObj.topMenuTree = topMenuTree;
            dataObj.activeMenuId = dataObj.topMenuTree.topMenus[0].menuId;
            dataObj.treeData = [dataObj.topMenuTree.trees[0].treeData];
        }
        const isActive=(menuId)=>{
            return menuId == dataObj.activeMenuId;
        }
        //切换顶部tabs
        const tagClick=(menuId)=>{
            if (menuId == dataObj.activeMenuId) return;

            let treeNode = dataObj.treeRef.data;
            let checkedKeys = dataObj.treeRef.getCheckedKeys();
            addSelKeys();
            removeUnSelKeys(treeNode, checkedKeys);

            dataObj.activeMenuId = menuId;
            let trees = dataObj.topMenuTree.trees;
            for (let i = 0; i < trees.length; i++) {
                if (menuId == trees[i].parentId) {
                    dataObj.treeData = [trees[i].treeData];
                    break;
                }
            }
            dataObj.treeRef.setCheckedKeys(dataObj.menuSelKeys);
        }
        const addSelKeys=()=>{
            let checkedKeys = dataObj.treeRef.getCheckedKeys();
            for (let i = 0; i < checkedKeys.length; i++) {
                if (dataObj.menuSelKeys.indexOf(checkedKeys[i]) == -1) {
                    dataObj.menuSelKeys.push(checkedKeys[i]);
                }
            }
        }
        const removeUnSelKeys=(nodes, checkedKeys)=>{
            for (let i = 0; i < nodes.length; i++) {
                let node = nodes[i];
                if (checkedKeys.indexOf(node.id) == -1 && dataObj.menuSelKeys.indexOf(node.id) > -1) {
                    dataObj.menuSelKeys.splice(dataObj.menuSelKeys.indexOf(node.id), 1);
                }
                if (node.children) {
                    removeUnSelKeys(node.children, checkedKeys);
                }
            }
        }

        const getSaveData=()=>{
            let treeNode = dataObj.treeRef.data;
            let checkedKeys = dataObj.treeRef.getCheckedKeys();
            addSelKeys();
            removeUnSelKeys(treeNode, checkedKeys);
            return {
                menuSelKeys: dataObj.menuSelKeys,
                menuSelPermissionIterms: dataObj.menuSelPermissionIterms
            };
        }
        const saveRoleMenuHandler=async()=>{
            const loading = proxy.$loading({lock: true, text: '正在保存,请稍后......', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
            let menuSelKeys = getSaveData().menuSelKeys;
            let menuSelPermissionIterms = getSaveData().menuSelPermissionIterms;

            if (menuSelKeys.length == 0) {
                utils.$$tools.info({ message: "没有选择任何菜单"});
                loading.close();
                return;
            }

            for (let key in menuSelPermissionIterms) {
                if (menuSelPermissionIterms[key].length == 0 ||menuSelKeys.indexOf(key) == -1) {
                    delete menuSelPermissionIterms[key];
                }
            }

            let menuPermissions = {};
            for (let key in menuSelPermissionIterms) {
                let perStr = "";
                for (let i = 0; i < menuSelPermissionIterms[key].length; i++) {
                    perStr = perStr + menuSelPermissionIterms[key][i] + ",";
                }
                perStr = perStr.substr(0, perStr.length - 1);
                menuPermissions[key] = perStr;
            }
            let params={roleId:dataObj.roleId,menuPermissions:menuPermissions};
            let res = await utils.$$api.saveRoleMenu(params);
            if(res.result){
                dataObj.enginInst.$parent.$parent.dialogVisible = false;
                utils.$$tools.success({ message: res.msg });
            }
            loading.close();
        }
        const resetRoleMenuHandler=()=>{
            addOrLoad(dataObj.enginInst);
            utils.$$tools.success({ message: "表单重置成功!"});
        }
        const selAll=(node, data)=>{
            selAllPermission(data);
        }
        const selAllPermission=(data)=>{
            const id=data.id;
            if(data.children){
                for(let i=0;i<data.children.length;i++){
                    selAllPermission(data.children[i]);
                }
            }else{
                const menuItermPermission=JSON.parse(data.menuItermPermission);
                let permissionValue=[];
                menuItermPermission.forEach((item,index)=>{
                    permissionValue.push(item.code);
                },permissionValue);
                dataObj.menuSelPermissionIterms[id]=permissionValue;
            }
        }
        const cancelAll=(node, data)=>{
            cancelAllPermission(data);
        }
        const cancelAllPermission=(data)=>{
            const id=data.id;
            if(data.children){
                for(let i=0;i<data.children.length;i++){
                    cancelAllPermission(data.children[i]);
                }
            }else{
                dataObj.menuSelPermissionIterms[id]='';
            }
        }
        return{
            ...toRefs(dataObj),addOrLoad,buildTopMenuTree,isActive,tagClick,addSelKeys,removeUnSelKeys,getSaveData,saveRoleMenuHandler,resetRoleMenuHandler,
            selAll,cancelAll
        }
    },
    components: {}
});
export default RoleMenuHelper;