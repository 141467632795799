import {$$str} from "@/utils/string";
import {$$post,$$upload,$$downLoad} from "@/utils/axios";
import {$$tools} from "@/utils/tools";
import {$$dialog} from "@/utils/dialog";
import {$$const} from "@/utils/const";
import $$api from "@/utils/api";
import {$$wjdcUtils} from "@/utils/wjdcUtils";

export default {
    $$str, $$post,$$upload,$$downLoad,$$tools, $$dialog,$$api,$$const,$$wjdcUtils
}

