<template>
    <div class="Wj">
        <el-form ref="formRef" :model="form" :rules="rules" label-width="100px">
            <el-row>
                <el-col :span="11">
                    <el-form-item label="名称" prop="name">
                        <el-input v-model="form.name" placeholder="请输入名称" maxlength="50" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="11" :offset="2">
                    <el-form-item label="调研时间" prop="time">
                        <el-date-picker v-model="form.time" placeholder="请选择调研时间" :disabled="disabled"/>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="11">
                    <el-form-item label="操作人">
                        <span>{{form.userId}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="11" :offset="2">
                    <el-form-item label="添加时间">
                        <span>{{form.addTime}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <EditTable :ref="el=>refMap.set('wjContent',el)" v-bind="cardDetailParam('wjContent')">
            <template v-slot:tbCols>
                <el-table-column label="调研城市" prop="F_DYCS"  align="left"/>
                <el-table-column label="调研方式" prop="F_DYFS"  align="left"/>
                <el-table-column label="调研地点" prop="F_DYDD"  align="left"/>
                <el-table-column label="调研时间" prop="F_DYSJ"  align="left"/>
                <el-table-column label="行业分类" prop="F_HYFL"  align="left"/>
                <el-table-column label="姓名" prop="F_XM"  align="left"/>
                <el-table-column label="电话" prop="F_DH"  align="left"/>
                <el-table-column label="居住地" prop="F_JZD"  align="left"/>
                <el-table-column label="籍贯地" prop="F_JGD"  align="left"/>
                <el-table-column label="驾驶人身份" prop="F_JSRSF"  align="left"/>
                <el-table-column label="性别" prop="F_XB"  align="left"/>
                <el-table-column label="年龄" prop="F_NL"  align="left"/>
                <el-table-column label="家庭年收入" prop="F_JTNSR"  align="left"/>
                <el-table-column label="文化水平" prop="F_WHSP"  align="left"/>
                <el-table-column label="家庭成员" prop="F_JTCY"  align="left"/>
                <el-table-column label="平时兴趣爱好" prop="F_PSXQAH"  align="left"/>
                <el-table-column label="车牌号" prop="F_CPH"  align="left"/>
                <el-table-column label="车辆名称" prop="F_CLMC"  align="left"/>
                <el-table-column label="驾驶室" prop="F_JSS"  align="left"/>
                <el-table-column label="单双排" prop="F_DSP"  align="left"/>
                <el-table-column label="发动机排量" prop="F_FDJPL"  align="left"/>
                <el-table-column label="排放标准" prop="F_PFBZ"  align="left"/>
                <el-table-column label="单双轮" prop="F_DSL"  align="left"/>
                <el-table-column label="货箱形式" prop="F_HXXS"  align="left"/>
                <el-table-column label="货箱长度" prop="F_HXCD"  align="left"/>
                <el-table-column label="车门总质量" prop="F_CMZZL"  align="left"/>
                <el-table-column label="购买时间" prop="F_GMSJ"  align="left"/>
                <el-table-column label="价格标准" prop="F_JGBZ"  align="left"/>
                <el-table-column label="成交价格" prop="F_CJJG"  align="left"/>
                <el-table-column label="分期期数" prop="F_FQQS"  align="left"/>
                <el-table-column label="是否免息" prop="F_SFMX"  align="left"/>
                <el-table-column label="首付" prop="F_SF"  align="left"/>
                <el-table-column label="月供" prop="F_YG"  align="left"/>
                <el-table-column label="计划使用期限（年）" prop="F_JHSYQX"  align="left"/>
                <el-table-column label="二手车期望价格（万）" prop="F_ESCQWJG"  align="left"/>
                <el-table-column label="历史拥有商用车数量" prop="F_LSYYSYCSL"  align="left"/>
                <el-table-column label="购车性质" prop="F_GCXZ"  align="left"/>
<!--                <el-table-column label="够买方式" prop="F_GMFS"  align="left"/>-->
                <el-table-column label="购买动机" prop="F_GMDJ"  align="left"/>
                <el-table-column label="动机属性" prop="F_DJSX"  align="left"/>
                <el-table-column label="购车是否伴随换业" prop="F_GCSFBSHY"  align="left"/>
                <el-table-column label="当前职业" prop="F_DQZY"  align="left"/>
                <el-table-column label="从业年限" prop="F_CYNX"  align="left"/>
                <el-table-column label="之前职业" prop="F_ZQZY"  align="left"/>
                <el-table-column label="换业原因" prop="F_HYYY"  align="left"/>
                <el-table-column label="选择当前尺寸/吨位微卡原因" prop="F_XZYY"  align="left"/>
                <el-table-column label="上一辆车类型" prop="F_SYLCLX"  align="left"/>
                <el-table-column label="上一辆车品牌、车型" prop="F_SYLCPP"  align="left"/>
                <el-table-column label="货箱形式" prop="F_SYLCHXXS"  align="left"/>
                <el-table-column label="是否是二手车" prop="F_SYLCSFESC"  align="left"/>
                <el-table-column label="用了几年" prop="F_SYLCYLJN"  align="left"/>
                <el-table-column label="再上一辆车类型" prop="F_ZSYLCLX"  align="left"/>
                <el-table-column label="再上一辆车品牌、车型" prop="F_ZSYLCPP"  align="left"/>
                <el-table-column label="货箱形式" prop="F_ZSYLCHXXS"  align="left"/>
                <el-table-column label="是否是二手车" prop="F_ZSYLCSFESC"  align="left"/>
                <el-table-column label="用了几年" prop="F_ZSYLCYLJN"  align="left"/>
                <el-table-column label="购车渠道" prop="F_GCQD"  align="left"/>
                <el-table-column label="拟购关注点" prop="F_NGGZD"  align="left"/>
                <el-table-column label="对比竞品及车型" prop="F_DBJPJCX"  align="left"/>
                <el-table-column label="放弃与下定原因" prop="F_FQYXDYY"  align="left"/>
                <el-table-column label="车辆用途" prop="F_CLYT"  align="left"/>
                <el-table-column label="改/加装配置" prop="F_JZPZ"  align="left"/>
                <el-table-column label="主要货物" prop="F_ZYHW"  align="left"/>
                <el-table-column label="载货方式" prop="F_ZHFS"  align="left"/>
                <el-table-column label="日常载重" prop="F_RCZZ"  align="left"/>
                <el-table-column label="最大载重" prop="F_ZDZZ"  align="left"/>
                <el-table-column label="当前里程" prop="F_DQLC"  align="left"/>
                <el-table-column label="日均里程" prop="F_RJLC"  align="left"/>
                <el-table-column label="线路是否固定" prop="F_XLSFGD"  align="left"/>
                <el-table-column label="主要行驶路况" prop="F_ZYXSLK"  align="left"/>
                <el-table-column label="描述您一天的工作及生活场景及常使用车辆的地方" prop="F_ZCSYDF"  align="left"/>
                <el-table-column label="计算综合油耗" prop="F_JSZHYH"  align="left"/>
                <el-table-column label="保养次数" prop="F_BYCS"  align="left"/>
                <el-table-column label="4S店次数" prop="F_SDCS"  align="left"/>
                <el-table-column label="维修站次数" prop="F_WXZCS"  align="left"/>
                <el-table-column label="保养价格" prop="F_BYJG"  align="left"/>
                <el-table-column label="车辆使用满意点" prop="F_CLSYMYD"  align="left"/>
                <el-table-column label="满意点请详细说明" prop="F_MYDXQMS"  align="left"/>
                <el-table-column label="车辆使用不满点" prop="F_CLSYBMD"  align="left"/>
                <el-table-column label="不满意点请详细说明" prop="F_BMDXXSM"  align="left"/>
                <el-table-column label="针对此车是否有其他改进建议" prop="F_QTJY"  align="left"/>
                <el-table-column label="会再次购买或推荐朋友购买吗" prop="F_ZCGM"  align="left"/>
                <el-table-column label="这辆车进城什么限制吗" prop="F_JCXZ"  align="left"/>
                <el-table-column label="您是如何应对这些限制" prop="F_RHYDXZ"  align="left"/>
                <el-table-column label="您对未来的职业规划是怎样考虑的" prop="F_ZYGH"  align="left"/>
                <el-table-column label="如果继续从事当前职业再买车想买什么样的车" prop="F_XMCL"  align="left"/>
                <el-table-column label="您所期望的车是什么样（动力、货箱、载重、价格等方面）" prop="F_XWCL"  align="left"/>
            </template>
        </EditTable>

        <el-dialog title="导入数据" v-model="importDataDialogVisible" width="50%"
                   :destroy-on-close="true" :close-on-click-modal="false" :append-to-body="true">
            <div>
                <div>
                    <el-steps :active="3" finish-status="info">
                        <el-step title="步骤 1" icon="iconfont icon-xiazai" description="模板下载"></el-step>
                        <el-step title="步骤 2" icon="el-icon-edit" description="对模板进行数据填充"></el-step>
                        <el-step title="步骤 3" icon="el-icon-upload" description="上传填好数据的模板"></el-step>
                    </el-steps>
                </div>
                <div style="width:100%;margin-top: 30px;display: flex;align-items: center;justify-content: space-evenly">
                    <div>
                        <el-button type="success" round @click="downLoad">模板下载</el-button>
                    </div>
                    <div><Upload v-bind="uploadParams"></Upload></div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>


<script>
    import WjCardHelper from "./WjCardHelper.js";
    export default WjCardHelper;
</script>

<style scoped>
    .Wj{
        width: 100%;
    }
</style>
