<template>
    <div id="app">
        <router-view v-slot="{ Component }">

            <keep-alive>
                <component :is="Component"/>
            </keep-alive>
        </router-view>
    </div>
</template>

<script>
    import { reactive,toRefs,defineComponent} from 'vue';
    export default defineComponent({
        name: 'App',
        setup(props,context){
            let dataObj=reactive({
                animationName:!localStorage.getItem('routeAnimation')?'el-zoom-in-center':localStorage.getItem('routeAnimation')
            });
            return{
                ...toRefs(dataObj)
            }
        }
    });
</script>

<style>
    .fade-transform-leave-active,
    .fade-transform-enter-active {
        transition: all 0.5s;
    }
    .fade-transform-enter {
        opacity: 0;
        transform: translateX(-30px);
    }
    .fade-transform-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }


    * {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    html,
    body,
    #app {
        width: 100%;
        height: 100%;
        overflow: hidden;
        perspective: 1000px;
    }

    #pageList {
        height: 100%;
    }
    .myRow {
        /*margin-bottom: 5px;*/
    }

    .myInput {
        width: 250px;
    }

    .myColTitle {
        text-align: right;
        line-height: 35px;
    }

    a {
        text-decoration: none;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
    }

    .el-dialog.is-fullscreen {
        overflow: hidden !important;
    }

    .el-drawer {
        overflow: auto !important;
    }

    .el-dialog__body {
        padding: 10px !important;
        padding-bottom: 0px !important;
    }
    .el-dialog__footer{
        padding-top: 3px !important;
    }
    .el-drawer__body{
        overflow: hidden !important;
    }
</style>
