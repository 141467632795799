<template>
    <div id="pageList" class="CustomerList">
        <el-tabs v-model="pageList.queryParam.activeName" @tab-click="tabHandleClick">
            <el-tab-pane label="基本信息" name="baseInfo"/>
            <el-tab-pane label="维修信息" name="repairInfo"/>
            <el-tab-pane label="保养信息" name="keepInfo"/>
        </el-tabs>
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">移动电话：</el-col>
                    <el-col :span="9">
                        <el-input placeholder="请输入移动电话进行模糊匹配查询..." v-model="pageList.queryParam.phone" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">VIN码：</el-col>
                    <el-col :span="9">
                        <el-input placeholder="请输入VIN码进行模糊匹配查询..." v-model="pageList.queryParam.cjh" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">发动机编号：</el-col>
                    <el-col :span="9">
                        <el-input placeholder="请输入发动机编号进行模糊匹配查询..." v-model="pageList.queryParam.fdjh" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">牌照号：</el-col>
                    <el-col :span="9">
                        <el-input placeholder="请输入牌照号进行模糊匹配查询..." v-model="pageList.queryParam.cph" clearable/>
                    </el-col>
                </el-row>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_DQDM" label="大区代码"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_DQMC" label="大区名称"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_SQDM" label="省区代码"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_SQMC" label="省区名称"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_QYDM" label="区域代码"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_QYMC" label="区域名称"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_JXSDM" label="经销商代码"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_JXSMC" label="经销商名称"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_JXSJC" label="经销商简称"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_WDDM" label="网点代码"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_WDJC" label="网点简称"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_WDSX" label="网点属性"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_XSY" label="销售员"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_KHDM" label="客户代码"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_KHXM" label="客户名称"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_KHLX" label="客户类型"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_ZJBH" label="证件编号"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_JDRQ" label="建档日期"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_LSFP_RQ" label="零售发票日期"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_XSFP_TPSC" label="零售发票图片上传"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_HKYTPSC" label="户口页图片上传"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_DALY" label="档案来源"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_VIN" label="VIN码"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_CKRQ" label="出库日期"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_CPDM" label="产品代码"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_CPMS" label="产品描述"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_CLPZ" label="车辆配置"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_CPXL" label="产品系列"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_GGXH" label="公告型号"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_YSMS" label="颜色描述"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_SC_RQ" label="生产日期"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_FDJLX" label="发动机类型"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_FDJBH" label="发动机编号"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_HGZH" label="合格证号"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_DYH" label="大用户"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_LZRQ" label="领证日期"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_LJXLGS" label="累计修理工时"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_XSLC" label="行驶里程"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_PZH" label="牌照号"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_FPH" label="发票号"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_BFB" label="百分比"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_LSJE" label="零售金额"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_SFYJD_LGM" label="是否一级代理购买"  v-if="pageList.queryParam.activeName=='baseInfo'">
                    <template #default="scope">
                        {{getStatus(scope.row.F_SFYJD_LGM)}}
                    </template>
                </el-table-column>
                <el-table-column prop="F_HT_XH" label="合同序号"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_LXR" label="联系人"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_DH" label="电话"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_YDDH" label="移动电话"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_LXDZ" label="联系地址"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_SF" label="省份"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_CS" label="城市"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_QX" label="县区"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_LXR_SF" label="联系人省份"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_LXR_CS" label="联系人城市"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_LXR_QX" label="联系人区县"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_TYBS" label="停用标识"  v-if="pageList.queryParam.activeName=='baseInfo'">
                    <template #default="scope">
                        {{getStatus(scope.row.F_TYBS)}}
                    </template>
                </el-table-column>
                <el-table-column prop="F_XYZHMC" label="信用账户名称"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
                <el-table-column prop="F_FKBS" label="付款标识"  v-if="pageList.queryParam.activeName=='baseInfo'"/>
               
                <!-- *** -->
                <el-table-column prop="F_NAME" label="客户姓名"  v-if="pageList.queryParam.activeName!='baseInfo'"/>
                <el-table-column prop="F_PHONE" label="客户电话"  v-if="pageList.queryParam.activeName!='baseInfo'" />
                <el-table-column prop="F_CJH" label="车架号" v-if="pageList.queryParam.activeName!='baseInfo'"/>
                <el-table-column prop="F_FDJH" label="发动机号" v-if="pageList.queryParam.activeName!='baseInfo'"/>
                <el-table-column prop="F_CPH" label="车牌号" v-if="pageList.queryParam.activeName!='baseInfo'"/>
                <el-table-column prop="F_CX" label="车型" v-if="pageList.queryParam.activeName!='baseInfo'"/>
                <el-table-column prop="F_GCRQ" label="购车日期" v-if="pageList.queryParam.activeName!='baseInfo'"/>
                

                <el-table-column prop="F_REPAIR_TIME" label="维修时间" v-if="pageList.queryParam.activeName=='repairInfo'"/>
                <el-table-column prop="F_REPAIR_NUM" label="维修次数" v-if="pageList.queryParam.activeName=='repairInfo'"/>
                <el-table-column prop="F_FAULT" label="故障现象" v-if="pageList.queryParam.activeName=='repairInfo'"/>
                <el-table-column prop="F_REPAIR_ITEMS" label="维修项目" v-if="pageList.queryParam.activeName=='repairInfo'"/>
                <el-table-column prop="F_REPAIR_MILEAGES" label="维修里程" v-if="pageList.queryParam.activeName=='repairInfo'"/>
                <el-table-column prop="F_REPAIR_PLACE" label="维修服务中心" v-if="pageList.queryParam.activeName=='repairInfo'"/>

                <el-table-column prop="F_KEEP_TIME" label="保养时间" v-if="pageList.queryParam.activeName=='keepInfo'"/>
                <el-table-column prop="F_KEEP_NUM" label="保养次数" v-if="pageList.queryParam.activeName=='keepInfo'"/>
                <el-table-column prop="F_KEEP_ITEMS" label="保养项目" v-if="pageList.queryParam.activeName=='keepInfo'"/>
                <el-table-column prop="F_KEEP_MILEAGES" label="保养里程" v-if="pageList.queryParam.activeName=='keepInfo'"/>
                <el-table-column prop="F_KEEP_PLACE" label="保养服务中心" v-if="pageList.queryParam.activeName=='keepInfo'"/>
            </template>
        </ListEngine>

        <el-dialog title="导入数据" v-model="importDataDialogVisible" width="50%"
                   :destroy-on-close="true" :close-on-click-modal="false" :append-to-body="true">
            <div>
                <div>
                    <el-steps :active="3" finish-status="info">
                        <el-step title="步骤 1" icon="iconfont icon-xiazai" description="模板下载"></el-step>
                        <el-step title="步骤 2" icon="el-icon-edit" description="对模板进行数据填充"></el-step>
                        <el-step title="步骤 3" icon="el-icon-upload" description="上传填好数据的模板"></el-step>
                    </el-steps>
                </div>
                <div style="width:100%;margin-top: 30px;display: flex;align-items: center;justify-content: space-evenly">
                    <div>
                        <el-button type="success" round @click="downLoad">模板下载</el-button>
                    </div>
                    <div><Upload v-bind="uploadParams"></Upload></div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide} from 'vue';
    export default defineComponent ({
        name: "CustomerList",
        setup(){
            // 过滤true/false
            const getStatus = (val)=>{
                let str = ''
                if(val=='false'){
                    str = '否'
                }else if(val==undefined){
                    str = ''
                }else{
                    str = '是'
                }
                return str
            }
            const {proxy}=getCurrentInstance();
            provide('uploadOwner', proxy);
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                importDataDialogVisible:false,
                importType:0,
                uploadParams:{
                    limitNum:5000,
                    action:'/customer/uploadFiles',
                },
                pageList: {
                    isShowFixCol:false,
                    queryParam: {
                        activeName:'baseInfo'
                    },
                    reduceOthers:45, 
                    modelMethod: "/customer/pageData"
               }
            })
            onMounted(()=>{
                dataObj.pageListRef.getTbInst().setPagerHeight(140);
            })
            //---------------------------computed------------
            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    // if(params && 'userId'==params.comboId){
                    //     return [{value:'0',label:'否'},{value:'1',label:'是'}]
                    // }
                }
            })
            const tabHandleClick=async (tab)=>{
                const loading = proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                let tabName=tab.props.name;
                await dataObj.pageListRef.loadPageData();
                loading.close();
            }
            //下载文件
            const downLoad=async ()=>{
                let sourceName="400客户基本信息-导入模板.xlsx";
                if(dataObj.pageList.queryParam.activeName=='repairInfo')sourceName="400客户维修信息-导入模板.xlsx";
                if(dataObj.pageList.queryParam.activeName=='keepInfo')sourceName="400客户保养信息-导入模板.xlsx";
                let res = await utils.$$downLoad({cluserServer:1,url:'/customer/downLoadResourceFile', params: {sourceName:sourceName,fileName:sourceName}});
            }
            //新增导入
            const importHandler=()=>{
                dataObj.importType=0;
                dataObj.importDataDialogVisible=true;
            }
            //覆盖导入
            const appendImportHandler=()=>{
                dataObj.importType=1;
                dataObj.importDataDialogVisible=true;
            }
            //上传的时候，增加参数传入到后台
            const buildUploadParams=(formData,params,proxy)=>{
                formData.append('type',dataObj.pageList.queryParam.activeName);
                formData.append('importType',dataObj.importType);
            }
            //上传完成之后
            const afterResult=async (res)=>{
                if(res.result){
                    const loading = proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                    await dataObj.pageListRef.loadPageData();
                    loading.close();
                }
            }
            const exportExcelHandler=()=>{
                console.log('导出')
                let tbData=dataObj.pageListRef.getTbData();
                if(tbData.length>0){
                    utils.$$tools.configBox({
                        msgContent:'确定执行该操作吗?',
                        fn:async ()=> {
                            const loading = proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                            await exportExcel(tbData);
                            loading.close();
                        }
                    });
                }else{
                    proxy.$message("没有内容可以导出");
                }
            }
            const formatJson=(fields, jsonData)=>{
                return jsonData.map(rowData => fields.map(field => rowData[field]))
            }
            const exportExcel=(tbData)=>{
                return new Promise((resolve, reject) => {
                    let fields=dataObj.pageListRef.getTbInst().tbCols.fields;
                    let labels=dataObj.pageListRef.getTbInst().tbCols.labels;
                    let data = formatJson(fields, tbData);//处理之后的tbData
                    if(data.length>0){
                        data.forEach((item,i)=>{
                            if(data[i][42]=='false'){
                                data[i][42] = '否'
                            }else if(data[i][42]==undefined){
                                data[i][42] = ''
                            }else{
                                data[i][42] = '是'
                            }
                            if(data[i][54]=='false'){
                                data[i][54] = '否'
                            }else if(data[i][54]==undefined){
                                data[i][54] = ''
                            }else{
                                data[i][54] = '是'
                            }
                        })
                    }
                    let title="";
                    switch (dataObj.pageList.queryParam.activeName){
                        case "baseInfo":title="基本信息";break;
                        case "repairInfo":title="维修信息";break;
                        case "keepInfo":title="保养信息";break;
                    }
                    proxy.excelUtils(labels, data, title);
                    resolve();
                }).catch(err => {
                    console.log('导出excel出错了')
                });
            }
            const gridLoaded=(res)=>{
                // '','','','','','',
                let baseField=['F_NAME','F_PHONE','F_CJH','F_FDJH','F_CPH','F_CX'];
                let baseLabel=['客户姓名','客户电话','车架号','发动机号','车牌号','车型'];
                let baseFieldNew=[
                    'F_DQDM','F_DQMC','F_SQDM','F_SQMC','F_QYDM','F_QYMC',
                    'F_JXSDM','F_JXSMC','F_JXSJC','F_WDDM','F_WDJC','F_WDSX',
                    'F_XSY','F_KHDM','F_KHXM','F_KHLX','F_ZJBH','F_JDRQ',
                    'F_LSFP_RQ','F_XSFP_TPSC','F_HKYTPSC','F_DALY','F_VIN','F_CKRQ',
                    'F_CPDM','F_CPMS','F_CLPZ','F_CPXL','F_GGXH','F_YSMS',
                    'F_SC_RQ','F_FDJLX','F_FDJBH','F_HGZH','F_DYH','F_LZRQ',
                    'F_LJXLGS','F_XSLC','F_PZH','F_FPH','F_BFB','F_LSJE',
                    'F_SFYJD_LGM','F_HT_XH','F_LXR','F_DH','F_YDDH','F_LXDZ',
                    'F_SF','F_CS','F_QX','F_LXR_SF','F_LXR_CS','F_LXR_QX',
                    'F_TYBS','F_XYZHMC','F_FKBS'
                ];
                let baseLabelNew=[
                    '大区代码','大区名称','省区代码','省区名称','区域代码','区域名称',
                    '经销商代码','经销商名称','经销商简称','网点代码','网点简称','网点属性',
                    '销售员','客户代码','客户名称','客户类型','证件编号','建档日期',
                    '零售发票日期','零售发票图片上传','户口页图片上传','档案来源','VIN码','出库日期',
                    '产品代码','产品描述','车辆配置','产品系列','公告型号','颜色描述',
                    '生产日期','发动机类型','发动机编号','合格证号','大用户','领证日期',
                    '累计修理工时','行驶里程','牌照号','发票号','百分比','零售金额',
                    '是否一级代理购买','合同序号','联系人','电话','移动电话','联系地址',
                    '省份','城市','县区','联系人省份','联系人城市','联系人区县',
                    '停用标识','信用账户名称','付款标识'

                ]
                switch (dataObj.pageList.queryParam.activeName){
                    case "baseInfo":
                        dataObj.pageListRef.getTbInst().tbCols.fields=baseFieldNew
                        dataObj.pageListRef.getTbInst().tbCols.labels=baseLabelNew
                        break;
                    case "repairInfo":
                        dataObj.pageListRef.getTbInst().tbCols.fields=baseField.concat(['F_REPAIR_TIME','F_REPAIR_NUM','F_FAULT','F_REPAIR_ITEMS','F_REPAIR_MILEAGES','F_REPAIR_PLACE']);
                        dataObj.pageListRef.getTbInst().tbCols.labels=baseLabel.concat(['维修时间','维修次数','故障现象','维修项目','维修里程','维修服务中心']);
                        break;
                    case "keepInfo":
                        dataObj.pageListRef.getTbInst().tbCols.fields=baseField.concat(['F_KEEP_TIME','F_KEEP_NUM','F_KEEP_ITEMS','F_KEEP_MILEAGES','F_KEEP_PLACE']);
                        dataObj.pageListRef.getTbInst().tbCols.labels=baseLabel.concat(['保养时间','保养次数','保养项目','保养里程','保养服务中心']);
                        break;
                }
            }
            return{
                ...toRefs(dataObj),comboSelect,tabHandleClick,downLoad,importHandler,appendImportHandler,buildUploadParams,afterResult,exportExcelHandler,gridLoaded,getStatus
            }
        }
    });
</script>

<style scoped>
    .CustomerList{
        width: 100%;
    }
</style>