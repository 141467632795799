const ADD_TABSVIEW = 'ADD_TABSVIEW';
const CLOSE_TABSVIEW = 'CLOSE_TABSVIEW';
const CLOSE_ALL_TABSVIEW = 'CLOSE_ALL_TABSVIEW';
const CLOSE_OTHER_TABSVIEW = 'CLOSE_OTHER_TABSVIEW';
const CHECK_TABSVIEW_EXIST = 'CHECK_TABSVIEW_EXIST';
const tabsview = {
    state: {
        visitedTabsView: []//目前已有的tabs
    },
    mutations: {
        [ADD_TABSVIEW](state, view) {
            if (state.visitedTabsView.find((n) => n.path === view.path))return;
            state.visitedTabsView.push({ title: view.meta.title, path: view.path });
        },
        [CLOSE_TABSVIEW](state, view) {
            for (let [i, v] of state.visitedTabsView.entries()) {
                if (v.path === view.path || v.title === view.title) {
                    state.visitedTabsView.splice(i, 1);break;
                }
            }
        },
        [CLOSE_ALL_TABSVIEW](state) {
            state.visitedTabsView.length=0;
            state.visitedTabsView.push({ title: "首页", path: "/welcome" })
        },
        [CLOSE_OTHER_TABSVIEW](state,view) {
            state.visitedTabsView.length=0;
            if(view.path!='/welcome')state.visitedTabsView.push({ title: "首页", path: "/welcome" })
            state.visitedTabsView.push({ title: view.title, path: view.path });
        },
        [CHECK_TABSVIEW_EXIST](state,view) {
            return state.visitedTabsView.some((n) => n.path === view.path);
        }
    },
    actions: {
        addVisitedTabsView({ commit }, view) {
            if(view.path!='/')commit(ADD_TABSVIEW, view)
        },
        closeSpeTabsView({ commit, state }, view) {
            return new Promise((resolve, reject) => {
                if(view.path!='/welcome'){
                    commit(CLOSE_TABSVIEW, view)
                    resolve([...state.visitedTabsView])
                }
            })
        },
        closeAllVisitedTabsView({ commit }) {
            commit(CLOSE_ALL_TABSVIEW)
        },
        closeOtherTabsView({ commit }, view) {
            commit(CLOSE_OTHER_TABSVIEW, view)
        },
        checkTabsViewExist({ commit,state }, view) {
            // commit(CHECK_TABSVIEW_EXIST, view)
            return new Promise((resolve, reject) => {
                if(view.path!='/welcome'){
                    let exist=state.visitedTabsView.some((n) => n.path === view.path);
                    resolve(exist);
                }
            })
        }
    },
    getters: {
        visitedTabsView: state => state.visitedTabsView
    }
}

export default tabsview