<template>
    <div class="roleCard">
        <el-form ref="formRef" :model="form" :rules="rules" label-width="80px">
<!--            <el-form-item label="编码" prop="code">-->
<!--                <el-input @input="e => form.code = validForbid(e)" v-model="form.code" maxlength="10" placeholder="请输入编码"/>-->
<!--            </el-form-item>-->
            <el-form-item label="名称" prop="name">
                <el-input @input="e => form.name = validForbid(e)" v-model="form.name" maxlength="100" placeholder="请输入名称"/>
            </el-form-item>
<!--            <el-form-item label="数据权限">-->
<!--                <el-select v-model="form.dataLevel" placeholder="请选择数据权限">-->
<!--                    <el-option label="本人" value="0"></el-option>-->
<!--                    <el-option label="本机构" value="3"></el-option>-->
<!--                    <el-option label="本机构及下级机构" value="4"></el-option>-->
<!--                </el-select>-->
<!--            </el-form-item>-->

            <el-form-item label="说明" prop="desc">
                <el-input @input="e => form.desc = validForbid(e)" type="textarea" v-model="form.desc" maxlength="100" placeholder="请输入说明"/>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    let form = {
        id: "",
        code: "",
        name: "",
        dataLevel: "0",
        portalLevel: "1",
        desc: ""
    };
    import {ref, reactive, toRefs, onMounted, defineComponent, getCurrentInstance, computed} from 'vue';
    export default defineComponent({
        name: "roleCard",
        title: "角色管理",
        modelType:'card',
        fullscreen: false,
        setup(){
           let dataObj=reactive({
               formRef:null,
               compParams: {
                   modelPath: "/role"
               },
               form: Object.assign({}, form),
               rules: {
                   code: [
                       {required: true, message: "请输入编码", trigger: "blur"}
                   ],
                   name: [
                       {required: true, message: "请输入名称", trigger: "blur"}
                   ]
               }
           })
           return{
               ...toRefs(dataObj)
           }
        },
        components: {}
    });
</script>

<style scoped>
    .roleCard{width: 100%;}
</style>
