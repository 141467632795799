<template>
    <div class="gridTable">
        <div class="tbInfo">
            <div class="tbHeader" v-if="showTitle">
                <slot name="_tbTitle">
                    <span class="titleFont">{{title}}</span>
                </slot>
            </div>
            <div class="tbToolBar" v-if="showToolBar">
                <slot name="_tbToolBar"></slot>
            </div>
            <div class="tbBody">
                <el-table
                        :data="tbData"
                        style="width: 100%;"
                        ref="tbRef" border
                        :header-cell-style="{
                            'background-color': '#fafafa',
                            'color': '#909399'
                        }"
                        :lazy="lazy"
                        :load="treeLazyLoad"
                        :row-key="rowKey"
                        :default-expand-all="expandAll"
                        :tree-props="treeProps"
                        :show-summary="showSummary"
                        :summary-method="getSummaries"
                        :cell-style="{padding: '0'}"
                        :fit="true"
                        :max-height="maxTbHeight"
                      
                        highlight-current-row
                        v-loading="loading"
                        element-loading-text="拼命加载中..."
                        :row-class-name="rowClassName"
                        @sort-change="sortChange"
                        @selection-change="selectionChange"
                        @current-change="currentChange"
                        @row-click="clickTableRow"
                        @select="checkBoxHandler"
                        @select-all="checkBoxAllHandler"
                        @cell-click="cellClick"
                        @cell-dblclick="cellDblclick"
                >
                    <!--表格组件默认提供了序号列，位于最左边-->
                    <el-table-column label="序号" fixed="left" type="index" :index="indexMethod" v-if="isShowIndexCol"/>
                    <slot name="_tbCols"></slot>
                </el-table>
            </div>
        </div>
        <div class="pageInfo" v-if="canPage" :style="{height: pagerHeight+'px'}">
            <el-pagination
                    background
                    :small="tbPagerInitParam.small"
                    @size-change="sizeChangeHandler"
                    @current-change="currentChangeHandler"
                    :current-page="currentPage"
                    :pager-count="tbPagerInitParam.pagerCount"
                    :page-sizes="tbPagerInitParam.pageSizes"
                    :page-size="tbPagerInitParam.pageSize"
                    :layout="tbPagerInitParam.layout"
                    :total="total"
            />
        </div>
    </div>
</template>

<script>
    import GridTableHelper from "@/components/base_comp/GridTableHelper.js";

    export default GridTableHelper;
</script>

<style scoped>
    .gridTable {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
    }

    .tbInfo {
        flex: 1; /*如果不显示分页条，好让grid充满*/
        display: flex;/*表格区域列布局：头、工具栏、内容*/
        flex-direction: column;
        justify-content: flex-start;
    }

    .tbHeader{
        height: 25px;
        background: linear-gradient(to bottom,#ecf0f1,#FAFAFA);
        /*为了和table一样长，需要减去1像素，不知道为什么table要这样*/
        width: calc(100% - 1);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-left: solid 1px #EBEEF5;
        border-right: solid 1px #EBEEF5;
    }
    .titleFont{
        font-size: 12px;
        font-weight: bold;
        color: rgb(144, 147, 153);
        margin-left: 10px;
    }
    .tbToolBar{
        height: 35px;
        /*为了和table一样长，需要减去1像素，不知道为什么table要这样*/
        width: calc(100% - 1);
        border-top: solid 1px #EBEEF5;
        background: #FAFAFA;
        border-left: solid 1px #EBEEF5;
        border-right: solid 1px #EBEEF5;
    }
    .tbBody{
        flex: 1;
    }
    .pageInfo {
        display: flex;
        justify-content: flex-end;
    }

    .gridTable ::v-deep(.el-table .cell){
        padding: 0;
        height: 100%;
    }
</style>
