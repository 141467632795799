import {createApp,ref} from 'vue'
import App from './App.vue'
import installElementPlus from './plugins/element';
import installJhComponent from './plugins/jhComponent';
import "./assets/iconfont.css" ;
import utils from './utils/jh.core.js';
import validForbid from './utils/rule.js';
const config = require('./utils/config.js');
import router from './router';
import store from './store';
import axios from 'axios';
import {export_json_to_excel,export_mul_head_excel} from './excel/Export2Excel.js';

(async () => {
    const app=createApp(App)
        .use(store)
        .use(router);
    installElementPlus(app,ref);
    installJhComponent(app);
    utils.$$tools.initCtx(app);
    config.bindAppProps(app,[
        {key:'$axios',value:axios},
        {key:'utils',value:utils},
        {key:'validForbid',value:validForbid},
        {key:'excelUtils',value:export_json_to_excel},
        {key:'exportMulHeadExcel',value:export_mul_head_excel}
    ]);
    await router.isReady();
    app.mount('#app');
})();
