<template>
    <div class="notExist">
        <p style="font-size: 40px;font-weight: bold;color: #00b4aa;margin-top: 20px;">时间都去哪儿了？你要的页面又去哪儿了？</p>
        <el-button type="text" @click="lockLoginOut">
            <el-link type="danger" :underline="false">退出重新登录</el-link>
        </el-button>
    </div>
</template>

<script>
    import { ref,computed,getCurrentInstance,reactive,toRefs,onMounted,defineComponent} from 'vue';
    export default defineComponent({
        name: "notExist",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let store = proxy.$store;
            let dataObj=reactive({

            })
            const lockLoginOut=async()=>{
                sessionStorage.removeItem("userInfo");
                let res = await utils.$$api.loginOut();
                utils.$$tools.success({message: "成功退出登录!"});
                proxy.$router.replace({path: "/login"});
            }
            return{
                ...toRefs(dataObj),lockLoginOut
            }
        }
    })
</script>

<style scoped>
    .notExist{
        width: 100%;
        height: 100%;
        background-color: skyblue;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        background-image: url("./404.gif");
        background-size: cover;
    }
</style>