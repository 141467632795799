<template>
    <div id="pageList" class="WjList">
        <el-tabs v-model="pageList.queryParam.activeName" @tab-click="tabHandleClick">
            <el-tab-pane label="基本信息" name="baseInfo"/>
            <el-tab-pane label="维修信息" name="repairInfo"/>
            <el-tab-pane label="保养信息" name="keepInfo"/>
        </el-tabs>
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">客户电话：</el-col>
                    <el-col :span="9">
                        <el-input placeholder="请输入客户电话进行模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">车架号：</el-col>
                    <el-col :span="9">
                        <el-input placeholder="请输入车架号进行模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">发动机号：</el-col>
                    <el-col :span="9">
                        <el-input placeholder="请输入发动机号进行模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">车牌号：</el-col>
                    <el-col :span="9">
                        <el-input placeholder="请输入车牌号进行模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                </el-row>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_NAME" label="名称"/>
                <el-table-column prop="F_TIME" label="调研时间"/>
                <el-table-column prop="F_ADD_TIME" label="添加时间"/>
                <el-table-column prop="F_USER_ID" label="操作人"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide} from 'vue';
    export default defineComponent ({
        name: "WjList",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                pageList: {
                    queryParam: {
                        activeName:''
                    },
                    reduceOthers:45,
                    modelMethod: "/customer/pageData"
               }
            })
            onMounted(()=>{
            })
            //---------------------------computed------------
            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    // if(params && 'userId'==params.comboId){
                    //     return [{value:'0',label:'否'},{value:'1',label:'是'}]
                    // }
                }
            })
            const tabHandleClick=async (tab)=>{
                const loading = proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                let tabName=tab.props.name;
                await dataObj.pageListRef.loadPageData();
                loading.close();
            }
            return{
                ...toRefs(dataObj),comboSelect,tabHandleClick
            }
        }
    });
</script>

<style scoped>
    .WjList{
        width: 100%;
    }
</style>