<template>
    <el-form ref="formRef" :model="form" :rules="rules" label-width="80px" class="userCard">
        <el-form-item label="帐号" prop="code">
            <el-input @input="e => form.code = validForbid(e)" v-model="form.code" maxlength="30" placeholder="请输入帐号" clearable/>
        </el-form-item>
        <el-form-item label="名称" prop="name">
            <el-input @input="e => form.name = validForbid(e)" v-model="form.name" maxlength="50" placeholder="请输入名称" clearable/>
        </el-form-item>

        <el-form-item label="所属人员" prop="personId">
            <el-select :ref="el=>refMap.set('personId',el)" v-model="form.personId" placeholder="请选择所属人员" clearable style="width:100%">
                <el-option v-for="item in comboSelect({comboId:'personId'})" :key="item.value" :label="item.label" :value="item.value"/>
            </el-select>
        </el-form-item>

        <el-form-item label="角色">
            <el-select :ref="el=>refMap.set('roleId',el)" v-model="form.roleId" placeholder="请选择角色" multiple clearable style="width:100%">
                <el-option v-for="item in comboSelect({comboId:'roleId'})" :key="item.value" :label="item.label" :value="item.value"/>
            </el-select>
        </el-form-item>

        <el-form-item label="说明" prop="desc">
            <el-input @input="e => form.desc = validForbid(e)" type="textarea" v-model="form.desc" clearable/>
        </el-form-item>

        <el-form-item>
           <div style="display: flex;justify-content: space-between">
               <h2 style="color:red">初始密码为: a123456</h2>
               <h6 style="color:red">{{form.stopInfo}}</h6>
           </div>
        </el-form-item>
    </el-form>
</template>

<script>
    import UserCardHelper from "./UserCardHelper.js";

    export default UserCardHelper;
</script>

<style scoped>
    .userCard{width: 100%;}
</style>
