<template>
    <div class="outerLink">

    </div>
</template>

<script>
    export default {
        name: 'OuterLink',
        setup() {

        }
    }
</script>

<style scoped>
    .outerLink{
        width: 100%;
        height: 100%;
    }
</style>
