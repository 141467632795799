<template>
    <div class="top" :style="{background:themeCss.top.background}">
        <div class="top_left">
            <!--折叠左侧菜单-->
            <i :class="menuIcon" title="折叠/打开" @click="collapseHandler" style="display: flex;align-items: center;font-size: 25px;color: #fff;cursor: pointer;"/>
            <!--logo-->
            <div style="display: flex;align-items: center"><img :src="logoPng" style="height:36px;cursor: pointer;" @click="logoHandler"/></div>
            <!--系统名称-->
            <div style="height:56px;display: flex;align-items: center;"><h3>{{sysTitle}}</h3></div>
        </div>

        <div class="top_right">
            <!--搜索菜单菜单-->
            <div class="topRightIcon search_font">
                <i class="el-icon-search" @click="searchHandler" v-if="!isSearch"/>
                <el-autocomplete @blur="searchBlurHandler" v-if="isSearch" v-model="searchContent" ref="searchKeyRef" style="border-top: 0"
                                 :fetch-suggestions="querySearchHandler" placeholder="Search" :popper-append-to-body="false"
                                 :trigger-on-focus="false" @select="selectMenuHandler" clearable/>
            </div>
            <!--全屏-->
            <div class="topRightIcon">
                <el-tooltip placement="top" :append-to-body="false">
                    <template #content>全屏</template>
                    <i class="el-icon-full-screen icon" @click="fullScreenHandler"></i>
                </el-tooltip>
            </div>
            <!--个人信息-->
            <el-dropdown trigger="click" @command="personalCommandHandler" placement="top-start">
                <div style="display: flex;flex-direction: row;line-height: 56px;cursor: pointer;margin-right: 5px;">
                    <div style="display: flex;flex-direction: column;justify-content: center;height: 56px">
                        <el-avatar :src="avatarImage"/>
                    </div>
                    <span style="color: #fff;margin-left: 5px;">{{userInfo.userName}}</span>
                    <i class="el-icon-caret-bottom" style="line-height: 56px;color: #fff;"></i>
                </div>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item command="themeConfig" class="iconfont icon-zhuti">主页风格设置</el-dropdown-item>
                        <el-dropdown-item command="changePersonalInfos" icon="el-icon-edit" v-if="hasChangePersonalInfosPermission">修改个人信息</el-dropdown-item>
                        <el-dropdown-item command="logOut" icon="el-icon-back">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>

            <!--字体大小-->
            <div class="topRightIcon fontSelf">
                <el-dropdown trigger="click" @command="uiSizeCommandHandler">
                    <i class="iconfont icon-daxiao" style="font-size: 18px;color: white;"/>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item command="large" :disabled="elementUISize=='large'">large</el-dropdown-item>
                            <el-dropdown-item command="medium" :disabled="elementUISize=='medium'">medium</el-dropdown-item>
                            <el-dropdown-item command="small" :disabled="elementUISize=='small' || elementUISize==null">small</el-dropdown-item>
                            <el-dropdown-item command="mini" :disabled="elementUISize=='mini'">mini</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>

        <!--主页风格设置dialog-->
        <el-dialog title="主页风格设置" v-model="themeConfigVisible" :append-to-body="false" width="40%" @close="cancelConfig">
            <el-container>
                <el-header :style="{background:themeCss.top.background,height:'40px'}">
                    <div style="text-align: center;float: right">
                        <el-color-picker v-model="themeCss.top.background" @active-change="topColorPickerChangeHandler"/>
                    </div>
                </el-header>
                <el-container>
                    <el-aside :style="{background:themeCss.slide.background,width:'70px',height:'120px'}">
                        <div style="text-align: center;">
                            <el-color-picker v-model="themeCss.slide.background" @active-change="slideColorPickerChangeHandler"/>
                        </div>
                    </el-aside>
                    <el-main>
                        <el-alert title="清空颜色之后，将会恢复默认颜色显示" type="info" effect="dark" show-icon/>
                    </el-main>
                </el-container>
            </el-container>
            <template #footer>
                <div class="dialog-footer">
                    <el-button class="iconfont icon-shanchu" @click="cancelConfig()">取 消</el-button>
                    <el-button type="primary" class="iconfont icon-queding" @click="sureConfig()">确 定</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    const config = require('../../utils/config.js');
    import screenfull from "screenfull";
    import {ref, reactive, toRefs, onMounted, computed, getCurrentInstance, nextTick, defineComponent} from 'vue';
    export default defineComponent({
        name: "Top",
        setup(){
            const searchKeyRef=ref(null);
            const {proxy} = getCurrentInstance();
            const utils=proxy.utils;
            const defColor={
                topDefColor:'#242f42',
                slideDefColor:'#324157'
            }
            const dataObj=reactive({
                searchContent:'',
                isSearch:false,
                userInfo:sessionStorage.getItem('userInfo')?JSON.parse(utils.$$str.decrypt(sessionStorage.getItem('userInfo'))):'',
                userMenus:sessionStorage.getItem('userMenus')?JSON.parse(utils.$$str.decrypt(sessionStorage.getItem('userMenus'))):'',
                hasChangePersonalInfosPermission:utils.$$tools.getPropFromVuex('hasChangePersonalInfosPermission'),
                elementUISize:localStorage.getItem('elementUISize'),
                sysTitle:config.title,
                routeAnimation:{
                    defRouteAnimation:!localStorage.getItem('routeAnimation')?'el-zoom-in-center':localStorage.getItem('routeAnimation'),
                    options:[
                        {value:'el-zoom-in-center',label: '左右展开'},
                        {value:'fade-transform',label: '缓动消失'},
                        {value:'el-fade-in',label: '渐入渐出'}
                    ]
                },

                themeConfigVisible: false,
                themeCss: {
                    top: {
                        background: (localStorage.getItem('topBackground')?localStorage.getItem('topBackground'):defColor.topDefColor)
                    },
                    slide: {
                        background:(localStorage.getItem('slideBackground')?localStorage.getItem('slideBackground'):defColor.slideDefColor)
                    }
                },
                menuIcon: "el-icon-s-fold",
                avatarImage: require('../../assets/user.jpg'),
                logoPng: require("../../assets/picResources/logo.png")
            });

            onMounted(()=>{


            })
            const searchHandler=()=>{
                dataObj.isSearch=true;
                nextTick(async () => {
                    searchKeyRef.value.focus();
                });
            }
            const searchBlurHandler=()=>{
                if(dataObj.searchContent){
                    setTimeout(()=>{
                        if(dataObj.searchContent){
                            dataObj.isSearch=false;
                            dataObj.searchContent="";
                        }
                    },300);
                }else{
                    dataObj.isSearch=false;
                }
            }
            const filterMenuItem=(userMenus,queryString,parentTitle)=>{
                let filterResult=[];
                userMenus.forEach((item)=> {
                    if(item.children){
                        let preTitle=parentTitle?(parentTitle+item.meta.title+" > "):item.meta.title+" > ";
                        let tempResult=filterMenuItem(item.children,queryString,preTitle);
                        if(tempResult.length>0) filterResult=filterResult.concat(tempResult);
                    }else{
                        if(item.meta.title.toLowerCase().indexOf(queryString.toLowerCase())>-1)
                            filterResult.push({path: item.path, title:parentTitle+item.meta.title})
                    }
                });
                return filterResult;
            }
            const querySearchHandler=(queryString, cb)=>{
                let queryResult = queryString ? filterMenuItem(dataObj.userMenus,queryString) : dataObj.userMenus;
                let filterResult=[];
                queryResult.forEach((item)=> {
                    filterResult.push({value: item.title, path: item.path})
                });
                cb(filterResult);
            }
            const selectMenuHandler=(item)=>{
                if(item.path){
                    proxy.$router.push({path: item.path});
                }
            }

            const collapseHandler=()=>{
                if (dataObj.menuIcon == "el-icon-s-fold") {
                    dataObj.menuIcon = "el-icon-s-unfold";
                    proxy.$parent.slidbarRef.isCollapse = true;
                    proxy.$parent.contentClassFlag = true;
                } else {
                    dataObj.menuIcon = "el-icon-s-fold";
                    proxy.$parent.slidbarRef.isCollapse = false;
                    proxy.$parent.contentClassFlag = false;
                }
            }

            const personalCommandHandler=async(command)=>{
                switch (command) {
                    case "themeConfig":
                        dataObj.themeConfigVisible = true;
                        break;
                    case "changePersonalInfos":
                        await proxy.$router.replace({path: "/changePersonalInfos"});
                        break;
                    case "logOut":
                        utils.$$tools.configBox({
                            msgContent: "确定注销并退出系统吗？",
                            fn: async () => {
                                sessionStorage.removeItem("userInfo");
                                let res = await utils.$$api.loginOut();
                                utils.$$tools.success({message: "成功退出登录!"});
                                location.reload();
                            }
                        });
                        break;
                }
            }
            const uiSizeCommandHandler=(command)=>{
                utils.$$tools.configBox({
                    msgContent: "是否更改大小？",
                    fn: async () => {
                        localStorage.setItem("elementUISize", command);
                        window.location.reload();
                    }
                });
            }
            const fullScreenHandler=()=>{
                if (!screenfull.isEnabled) {
                    utils.$$tools.info({message: "你的浏览器不支持该功能"});
                    return false;
                }
                screenfull.toggle();
            }

            const cancelConfig=()=>{
                dataObj.themeCss.top.background = (localStorage.getItem('topBackground')?localStorage.getItem('topBackground'):defColor.topDefColor);
                dataObj.themeCss.slide.background = (localStorage.getItem('slideBackground')?localStorage.getItem('slideBackground'):defColor.slideDefColor);
                dataObj.themeConfigVisible = false;
            }
            const sureConfig=()=>{
                let topColor = dataObj.themeCss.top.background ? dataObj.themeCss.top.background : defColor.topDefColor;
                let slideColor = dataObj.themeCss.slide.background ? dataObj.themeCss.slide.background : defColor.slideDefColor;

                proxy.$parent.slidbarRef.backgroundColor = slideColor;
                dataObj.themeCss.top.background = topColor;
                dataObj.themeCss.slide.background = slideColor;
                dataObj.themeConfigVisible = false;

                localStorage.setItem('topBackground',topColor);
                localStorage.setItem('slideBackground',slideColor);
            }
            const topColorPickerChangeHandler=(curColor)=>{
                if(!curColor)dataObj.themeCss.top.background=defColor.topDefColor;
            }
            const slideColorPickerChangeHandler=(curColor)=>{
                if(!curColor)dataObj.themeCss.slide.background=defColor.slideDefColor;
            }
            const logoHandler=async ()=>{
                await proxy.$router.push('/');
            }
            return{
                ...toRefs(dataObj), searchKeyRef,
                searchHandler,searchBlurHandler,selectMenuHandler,querySearchHandler,
                collapseHandler,personalCommandHandler,uiSizeCommandHandler,fullScreenHandler,
                cancelConfig,topColorPickerChangeHandler,slideColorPickerChangeHandler,sureConfig,
                logoHandler
            }
        }
    });
</script>

<style scoped>

    .top {
        position: fixed;
        width: 100%;
        height: 56px;
        z-index: 10;
        display: flex;
        justify-content: space-between;
        background-color: rgba(5, 0, 17, 0.99);
        color: #fff;
    }

    .top_left {
        display: flex;
        font-weight: 400;
    }

    .top_right {
        flex: 1;
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        margin-right: 10px;
    }



    /*设置图标居中显示、大小、颜色*/
    .topRightIcon{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        color: #fff;
        cursor: pointer;
        margin-right: 20px;
    }
    /*搜索菜单图标大小*/
    .search_font{
        font-size: 20px;
    }

    .fontSelf{
        margin-right: 5px;
        margin-left: 10px;
    }


    /*更改顶部激活菜单的底部颜色*/
    .el-menu--horizontal > .el-menu-item.is-active {
        border-bottom-color: #242f42 !important;
        /*background: rgba(255, 0, 6, 0.07) !important;*/
    }
    /*顶部菜单的间距，默认相互之间的padding是20px，现在改为10px*/
    .el-menu-item{
        padding: 0 10px !important;
    }
    /*当搜索菜单的时候，菜单级数过深，autocomplete下拉可能显示不完搜索的内容，现在改为包裹搜索结果*/
    ::v-deep(.el-autocomplete-suggestion){
        width: auto!important;
    }
</style>