const SET_ENCRYPTDATA = 'SET_ENCRYPTDATA';
const SET_MSG_RECEIVE_PERMISSION = 'SET_MSG_RECEIVE_PERMISSION';
const SET_CHANGE_PERSONALINFO_PERMISSION = 'SET_CHANGE_PERSONALINFO_PERMISSION';
const SET_CURROUTE = 'SET_CURROUTE';
const others = {
    state: {
        encryptData: false,
        hasMessageReceivePermission:false,
        hasChangePersonalInfosPermission:false,
        curRoute:''//当前路由
    },
    mutations: {
        [SET_ENCRYPTDATA](state, encryptData) {
            state.encryptData = encryptData;
        },
        [SET_CURROUTE](state, curRoute) {
            state.curRoute = curRoute;
        },
        [SET_MSG_RECEIVE_PERMISSION](state, permission) {
            state.hasMessageReceivePermission = permission;
        },
        [SET_CHANGE_PERSONALINFO_PERMISSION](state, permission) {
            state.hasChangePersonalInfosPermission = permission;
        }
    },
    actions: {
        configEncryptData({commit}, encryptData) {
            commit(SET_ENCRYPTDATA, encryptData)
        },
        configCurRoute({commit}, curRoute) {
            commit(SET_CURROUTE, curRoute)
        },
        configMsgReceivePermission({commit}, permission) {
            commit(SET_MSG_RECEIVE_PERMISSION, permission)
        },
        configChangePersonalInfoPermission({commit}, permission) {
            commit(SET_CHANGE_PERSONALINFO_PERMISSION, permission)
        }
    },
    getters: {
        encryptData: state => state.encryptData,
        hasMessageReceivePermission: state => state.hasMessageReceivePermission,
        hasChangePersonalInfosPermission: state => state.hasChangePersonalInfosPermission,
        curRoute: state => state.curRoute
    }
}
export default others