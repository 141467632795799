<template>
    <div class="comboGrid">
        <el-popover ref="popoverRef" placement="bottom-start" :width="400" trigger="click" @hide="hide" :disabled="disabled" @after-enter="show" v-model:visible="visible">
            <GridTable ref="comboGridRef" v-bind="gridParam">
                <template v-slot:_tbToolBar v-if="comboParam.showToolBar">
                    <el-row style="margin: 1px;">
                        <slot name="toolBar">
                            <el-button icon="el-icon-edit" type="primary" @click="editHandler">编辑</el-button>
                            <el-button icon="el-icon-refresh" type="success" @click="refreshHandler">刷新</el-button>
                        </slot>
                    </el-row>
                </template>

                <template v-slot:_tbCols>
                    <el-table-column type="selection" v-if="comboParam.multiple"/>
                    <slot name="tbCols">
                        <el-table-column prop="F_CODE" label="编码" header-align="center" align="center"/>
                        <el-table-column prop="F_NAME" label="名称" header-align="center" align="center"/>
                    </slot>
                </template>
            </GridTable>
            <template #reference>
                <el-input :readonly="readOnly" v-model="comboText" placeholder="请选择" suffix-icon="el-icon-arrow-down" clearable
                          :disabled="disabled" slot="reference" style="cursor:pointer" @input="inputChange" @clear="inputClear"/>
            </template>
        </el-popover>
    </div>
</template>

<script>
    import ComboGridHelper from "./ComboGridHelper.js";

    export default ComboGridHelper;
</script>

<style scoped>
    .comboGrid{
        width: 100%;
        /*如果不设置如下布局，那么在明细表格中有该组件的时候，该组件在表格中就不会居中，很难看*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }
    .test{
        color: red;
    }
</style>
