import ListEngine from '@/components/base_comp/ListEngine';
import Dialog from '@/components/base_comp/Dialog';
import ComboGrid from '@/components/base_comp/ComboGrid';
import GridTable from '@/components/base_comp/GridTable';
import EditTable from '@/components/base_comp/EditTable';
import Upload from '@/components/base_comp/Upload';

export default (app) => {
  app.component('ListEngine', ListEngine);
  app.component('Dialog', Dialog);
  app.component('ComboGrid', ComboGrid);
  app.component('GridTable', GridTable);
  app.component('EditTable', EditTable);
  app.component('Upload', Upload);
}