import {createRouter, createWebHistory} from 'vue-router'
import utils from '@/utils/jh.core.js';
import store from '@/store'; // 引入store
const Welcome = () => import('@/views/sysviews/welcome/Welcome');
export const userRoutes = [
    {path: '/login', name: 'login', component: () => import('@/Login')},
    {
        path: '/',
        name: 'Main',
        redirect: '/welcome',
        meta: {icon: 'el-icon-message', title: '主界面'},
        component: () => import('@/components/layout/Main'),
        children: []
    },
    {path: '/404', name: '404', component: () => import('@/views/sysviews/404/404')}
];

let router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: userRoutes
});

//构建路由信息
export const routObj = {
    buildRoutes(userMenus,routeMap){
        for (let i = 0; i < userMenus.length; i++) {
            if (userMenus[i].children) {
                this.buildRoutes(userMenus[i].children, routeMap);
            } else {
                let routItem =this.buildLeafRouteItemInfo({
                    id: userMenus[i].id,
                    page: userMenus[i].page,
                    path: userMenus[i].path,
                    icon: userMenus[i].meta.icon,
                    title: userMenus[i].meta.title,
                    prop: userMenus[i].meta.prop,
                    show: userMenus[i].meta.show,
                    type: userMenus[i].meta.type
                });
                routeMap.set(routItem.path, routItem);
                userRoutes[1].children.push(routItem);
            }
        }
    },
    buildLeafRouteItemInfo(options) {
        let obj = {path: options.path, name: options.id};
        obj.meta = {//把菜单信息放到meta对象中
            icon: options.icon,
            title: options.title,
            prop: options.prop,
            show: options.show,
            type: options.type,
            page: options.page
        };
        if (options.page && !options.page.startsWith("http")){
            obj.component = async () => await import(`@/views${options.page}`);
        }else{
            obj.component = async () => await import('@/components/base_comp/Util');
        }
        return obj;
    },
    buildPortalsToRoute(portals,routeMap){
        let portalComponents = {};
        let welComeRoute = {
            path: '/welcome',
            name: 'welcome',
            meta: {icon: 'el-icon-menu', title: '首页'},
            components: Object.assign({}, {default: Welcome}, portalComponents)
        };
        routeMap.set(welComeRoute.path, welComeRoute);
        userRoutes[1].children.push(welComeRoute);
        userRoutes.forEach(item=>routeMap.set(item.path, item));
    },

    getComponentByPage(page) {
        return () => import(`@/views${page}`);
    }
};

router.beforeEach(async (to, from, next) => {

    if (to.path == '/login' || to.path == '/register') {
        sessionStorage.getItem("userInfo") == null?next():next('/');
    } else {
        if (sessionStorage.getItem("userInfo") == null) {
            utils.$$tools.info({message: "请先登录"});
            next('/login');
        } else {
            try {
                if (router.options.routes[1].children.length == 0) {
                    await utils.$$api.loadUserMenus({}).then(res => {
                        store.dispatch("configEncryptData", res.encryptData);
                        store.dispatch("configChangePersonalInfoPermission", res.hasChangePersonalInfosPermission);
                        let userMenus = JSON.parse(res.userMenus);
                        let routeMap=new Map();
                        routObj.buildRoutes(userMenus,routeMap);
                        routObj.buildPortalsToRoute('',routeMap);
                        routeMap.has(to.path) ? next({}) : next('/404');
                    }).catch(err=>{
                        utils.$$tools.info({message: "很遗憾网络有点小问题"});
                        sessionStorage.removeItem(utils.$$const.baseVar.jfAccessToken);
                        sessionStorage.removeItem("userInfo");
                        location.reload();
                    });
                }
            }catch (e) {
                next('/login');
            }
            if(to.fullPath!=store.getters.curRoute){
                await store.dispatch("configCurRoute", to.fullPath);
                userRoutes.forEach(item=>router.addRoute(item));
                next({...to, replace: true });
            }else{
                next()
            }
        }
    }
})

export default router
