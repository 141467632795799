import RoleCard from './RoleCard';
import RoleMenu from './RoleMenu';
import {ref, reactive, toRefs, onMounted, defineComponent, getCurrentInstance, computed} from 'vue';
const RoleListHelper = defineComponent({
    name: "roleList",
    props: {
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        let dataObj=reactive({
            pageListRef:null,
            pageList: {
                queryParam: {
                    code: "",
                    name: "",
                    from:props.engineParams?'others':''
                },
                modelComp:RoleCard,
                modelMethod: "/role/pageData"
            }
        })

        const showModule=async(options)=>{
            options=Object.assign({},{
                modelComp: RoleCard,
                ownerComp:dataObj.pageListRef
            },options);
            let vm = await utils.$$dialog.create(options);
            vm.dialogVisible = true;
        }
        //----------------列表数据表格的编辑、删除和角色授权
        const permissionHandler=async (row)=>{
            dataObj.operId = row.F_ID;
            await showModule({addOrEdit:'edit',id:row.F_ID,modelComp:RoleMenu,title: "菜单授权管理"});
        }
        //---------------数据表格行格式化
        const formatterDataLevel=(row, column, value, index)=>{
            if (0 == value) {
                return "本人";
            } else if (1 == value) {
                return "本部门";
            } else if (2 == value) {
                return "本部门及下级部门";
            } else if (3 == value) {
                return "本机构";
            } else if (4 == value) {
                return "本机构及下级机构";
            } else {
                return "";
            }
        }
        const formatterPortalLevel=(row, column, value, index)=>{
            if (0 == value) {
                return "查看";
            } else if (1 == value) {
                return "编辑";
            } else {
                return "";
            }
        }
        const filterDataLevel=(value, row)=>{
            return row.F_DATA_LEVEL === value;
        }
        const filterPortalLevel=(value, row)=>{
            return row.F_PORTAL_LEVEL === value;
        }
        return{
            ...toRefs(dataObj),permissionHandler,formatterDataLevel,formatterPortalLevel,filterDataLevel,filterPortalLevel
        }
    }
});
export default RoleListHelper;