export const $$const = {
    baseVar:{
        topHeight:56,
        tabHeight:36,
        tbHeaderHeight:25,
        tbToolBarHeight:35,
        jfAccessToken:'Jf-Access-Token',
        errorCode:'errorCode',
        downloadcode:'downloadcode'
    },
    sysCode:{
        _0000:'0000',
        _0001:'0001',
        _0002:'0002',
        _0009:'0009',
        _0010:'0010',
        _0014:'0014',
        _0015:'0015'
    }

}