import PersonCard from './PersonCard';
import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, provide} from 'vue';
const PersonListHelper = defineComponent({
    name: "pageList",
    props: {
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;

        let dataObj=reactive({
            pageListRef:null,
            corpTreeData: [],
            pageList: {
                queryParam: {
                    code: "",
                    name: "",
                    corpId: "",
                    from:props.engineParams?'others':''
                },
                modelComp: PersonCard,
                modelMethod: "/person/pageData"
            }
        })
        onMounted(()=>{
            init()
        })
        const init=async()=>{
            dataObj.corpTreeData = await utils.$$api.corpTreeData();
        }
        const change=async(val)=>{
        }


        return{
            ...toRefs(dataObj),init,change
        }
    },
    components: {}
});
export default PersonListHelper;