const ADDDETAILHANDLER = "addDetailHandler";
const BEFOREADDHANDLER = "beforeAddHandler";
const DELDETAILHANDLER = "delDetailHandler";
const BEFOREDELHANDLER = "beforeDelHandler";
const AFTERDELHANDLER = "afterDelHandler";
const CLICKTABLEROW = "clickTableRow";
const BEGANEDIT = "beganEdit";
const AFTERBEGANEDIT = "afterBeganEdit";
const GRIDLOADED = "gridLoaded";
const BEFOREENDEDIT = "beforeEndEdit";
const CURRENTCHANGE = "currentChange";
const BEFORECLEARDATA = "beforeClearData";
const AFTERCLEARDATA = "afterClearData";
const CELLCLICK = "cellClick";
const CELLDBLCLICK = "cellDblclick";
const UPHANDLER = "upHandler";
const DOWNHANDLER = "downHandler";
const BUILDTITLE = "buildTitle";
import {ref, reactive, toRefs, computed, defineComponent, onMounted, onBeforeMount, getCurrentInstance, watch, provide, inject} from 'vue';
const EditTableHelper = defineComponent({
    name: "EditTable",
    props: {
        detailParam: {
            type: Object
        }
    },
    setup(props,context){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        provide('gridTableOwner', proxy);

        let EditTableOwner = inject('EditTableOwner');
        let dataObj=reactive({
            editParams: {idField: "F_ID",title:'明细数据',canAdd: true,canUp:false,canDown:false,readOnly: false,isShowFixCol: true,toolBar: [],showTitle:true,showToolBar:true},//明细表格的参数
            fields: [],//表格所有列
            insert: [], //新增的数据
            update: [], //修改的数据
            delete: [], //删除的数据
            detailTypeRef:null,
            detailType: props.detailParam.queryParam.detailType
        })
        onBeforeMount(()=>{
            dataObj.editParams = Object.assign({autoLoad:false,tbId:dataObj.detailType},dataObj.editParams,props.detailParam);
        })
        onMounted(()=>{
            dataObj.fields=dataObj.detailTypeRef.tbCols.fields;
        })
        const clickHandler=(ev, param)=>{
            if (EditTableOwner[ev]) {
                EditTableOwner[ev](param);
            } else {
                proxy[ev](param);
            }
        }
        //设置表格只读
        const setReadOnly=async (val)=>{
            dataObj.editParams.readOnly=val;
            await resetTableNoEdit();
        }
        //表格头部新增按钮处理事件
        const addDetailHandler=async ()=>{
            if (EditTableOwner[ADDDETAILHANDLER]) {
                EditTableOwner[ADDDETAILHANDLER](dataObj.detailType);
            } else {
                let row = {EDIT:true};
                dataObj.fields.forEach((field)=> {
                    row[field] = "";
                }, row);
                if (EditTableOwner[BEFOREADDHANDLER] && !EditTableOwner[BEFOREADDHANDLER](dataObj.detailType, row)) return;
                if (EditTableOwner[BEGANEDIT] && !EditTableOwner[BEGANEDIT](dataObj.detailType)) return false;
                if(await resetTableNoEdit()){
                    dataObj.detailTypeRef.tbData.push(row);
                }
            }
        }
        const delDetailHandler=(row, index)=>{
            let idField = dataObj.editParams.idField;
            if (EditTableOwner[DELDETAILHANDLER]) {
                EditTableOwner[DELDETAILHANDLER](dataObj.detailType, row, index);
            } else {
                if (EditTableOwner[BEFOREDELHANDLER] && !EditTableOwner[BEFOREDELHANDLER](dataObj.detailType, row, index)) return;
                dataObj.detailTypeRef.tbData.splice(index, 1);
                if (row[idField]) {
                    dataObj.delete.push(row[idField]);
                }
                deleteFromInsert(row);
                deleteFromUpdate(row);
                if (EditTableOwner[AFTERDELHANDLER]) EditTableOwner[AFTERDELHANDLER](dataObj.detailType, row, index)
            }
        }
        const gridLoaded=(res)=>{
            resetArray();
            if (EditTableOwner[GRIDLOADED]) {
                EditTableOwner[GRIDLOADED](dataObj.detailType, res);
            } else {
                res.rows.forEach((row)=> {
                    row.EDIT = false;
                });
            }
        }
        const resetArray=()=>{
            dataObj.insert=[];dataObj.update=[];dataObj.delete=[];
        }
        const currentChange=(options)=>{
            if (EditTableOwner[CURRENTCHANGE]) {
                EditTableOwner[CURRENTCHANGE](dataObj.detailType, options);
            }
        }
        const clickTableRow=async (options)=>{
            let flag=true;
            if (EditTableOwner[CLICKTABLEROW]) {
                EditTableOwner[CLICKTABLEROW](dataObj.detailType, options);
            } else {
                if (dataObj.editParams.readOnly) return;
                if (options.row.EDIT) return;
                if (EditTableOwner[BEGANEDIT] && !await EditTableOwner[BEGANEDIT](dataObj.detailType, options)) return false;
                flag=await resetTableNoEdit();
                if(flag)dataObj.detailTypeRef.tbData[options.row.rowIndex].EDIT = !options.row.EDIT;
            }
            if (flag && !dataObj.editParams.readOnly && EditTableOwner[AFTERBEGANEDIT]) EditTableOwner[AFTERBEGANEDIT](dataObj.detailType, options);
        }
        const resetTableNoEdit=async ()=>{
            let idField = dataObj.editParams.idField;
            let tbData = dataObj.detailTypeRef.tbData;
            for (let i = 0; i < tbData.length; i++) {
                if (tbData[i].EDIT) {
                    if (EditTableOwner[BEFOREENDEDIT] && ! await EditTableOwner[BEFOREENDEDIT](dataObj.detailType, tbData[i], i)) return false;
                    tbData[i].EDIT = false;
                    if (tbData[i][idField]) {
                        dataObj.update.push(tbData[i]);
                    } else {
                        dataObj.insert.push(tbData[i]);
                    }
                }
            }
            dataObj.detailTypeRef.tbData = tbData;
            return true;
        }
        //获取表格引用
        const getTbInst=()=>{
            return dataObj.detailTypeRef;
        }
        //得到表格数据
        const getDetailData=()=>{//得到编辑表格数据
            return dataObj.detailTypeRef.tbData;
        }
        //清除表格数据
        const clearDetailData=async ()=>{
            let tbData=getDetailData();
            if (EditTableOwner[BEFORECLEARDATA] && !await EditTableOwner[BEFORECLEARDATA](dataObj.detailType, tbData)) return;
            tbData.forEach((row, index)=> {
                delDetailHandler(row,index);
            });
            dataObj.update.length=0;
            dataObj.insert.length=0;
            dataObj.detailTypeRef.clearTbData();
            if (EditTableOwner[AFTERCLEARDATA])EditTableOwner[AFTERCLEARDATA](dataObj.detailType,dataObj.delete)
        }
        //重新加载表格
        const reloadGrid=async(params)=>{
            await dataObj.detailTypeRef.queryHandler(true, params);
        }
        const getSaveDetailData=async()=>{
            if(!await resetTableNoEdit())return null;
            return {
                insert: [...new Set(dataObj.insert)],
                update: [...new Set(dataObj.update)],
                delete: [...new Set(dataObj.delete)]
            };
        }
        //删除insert数组中指定的项
        const deleteFromInsert=(row)=>{
            utils.$$str.delItemFromArr(dataObj.insert,row);
        }
        //删除update数组中指定的项
        const deleteFromUpdate=(row)=>{
            utils.$$str.delItemFromArr(dataObj.update,row);
        }
        //增加一行
        const addRow=(row)=>{
            dataObj.detailTypeRef.tbData.push(row);
            dataObj.insert.push(row);
        }
        //把某行放进新增数组中
        const setInsert=(row)=>{
            dataObj.insert.push(row);
        }
        //把某行放进修改数组中
        const setUpdate=(row)=>{
            dataObj.update.push(row);
        }
        //设置要后台删除行的id
        const setDelete=(id)=>{
            dataObj.delete.push(id);
        }
        //更新某行
        const setRowData=(row, index)=>{
            dataObj.detailTypeRef.tbData[index] = row;
        }
        //更新某行指定列的值
        const setCellData=(field,value, index)=>{
            dataObj.detailTypeRef.tbData[index][field]=value;
        }
        //单击单元格事件
        const cellClick=(row, column, cell, event)=>{
            if (EditTableOwner[CELLCLICK]) {
                EditTableOwner[CELLCLICK](row, column, cell, event);
            }
        }
        //单双击单元格事件
        const cellDblclick=(row, column, cell, event)=>{
            if (EditTableOwner[CELLDBLCLICK]) {
                EditTableOwner[CELLDBLCLICK](row, column, cell, event);
            }
        }
        //获取表格当前编辑行
        const getEditRow=()=>{
            let tbData = dataObj.detailTypeRef.tbData;
            return tbData.find((row,index,tbData)=>{
                return tbData[index].EDIT==true;
            });
        }
        //上移
        const upHandler=()=>{
            if (EditTableOwner[UPHANDLER]) {
                EditTableOwner[UPHANDLER](dataObj.detailType);
            } else {
                let grid=getTbInst();
                let selection=grid.getSelection();
                if(selection.length==1){
                    let rowIndex=selection[0].rowIndex;
                    if(rowIndex!=0){
                        let data=getDetailData();
                        let moveItem = data[rowIndex];
                        data[rowIndex] = data[rowIndex-1];
                        data[rowIndex-1] = moveItem;
                    }
                }
            }
        }
        //下移
        const downHandler=()=>{
            if (EditTableOwner[DOWNHANDLER]) {
                EditTableOwner[DOWNHANDLER](dataObj.detailType);
            } else {
                let grid=getTbInst();
                let selection=grid.getSelection();
                if(selection.length==1){
                    let rowIndex=selection[0].rowIndex;
                    let data=getDetailData();
                    if(rowIndex!=(data.length-1)){
                        let moveItem = data[rowIndex];
                        data[rowIndex] = data[rowIndex+1];
                        data[rowIndex+1] = moveItem;
                    }
                }
            }
        }
        //表格标题
        const buildTitle=(tbId,gridProxy)=>{
            if (EditTableOwner[BUILDTITLE]) {
               return EditTableOwner[BUILDTITLE](dataObj.detailType, proxy);
            }else{
                return dataObj.editParams.title;
            }
        }
        const getSummaries=(tbId,param)=>{
            if (EditTableOwner['getSummaries']) {
                return EditTableOwner['getSummaries'](tbId,param, proxy);
            }else{
                return [];
            }
        }
        return{
            ...toRefs(dataObj),clickHandler,setReadOnly,addDetailHandler,delDetailHandler,gridLoaded,resetArray,currentChange,
            clickTableRow,resetTableNoEdit,getDetailData,clearDetailData,reloadGrid,getSaveDetailData,deleteFromInsert,
            deleteFromUpdate,addRow,setInsert,setUpdate,setDelete,setRowData,setCellData,cellClick,cellDblclick,getTbInst,getEditRow,upHandler,downHandler,
            buildTitle,getSummaries
        }
    },

    components: {}
});
export default EditTableHelper;