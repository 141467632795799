import {createApp,ref} from "vue";
import installElementPlus from '../plugins/element';
import installJhComponent from '../plugins/jhComponent';
import Dialog from '../components/base_comp/Dialog';
import utils from '../utils/jh.core.js';
const config = require('./config.js');
import store from '../store';
import router from "../router";
import axios from "axios";
import {export_json_to_excel,export_mul_head_excel} from "../excel/Export2Excel";
import validForbid from "./rule";



export const $$dialog = {
    create(options) {
        return new Promise((resolve, reject) => {
            let defaults = {
                dialogDiv: "myAlert",
                showDialog: true
            };
            options = Object.assign({}, defaults, options);

            const dialogApp=createApp(Dialog)
                            .use(store)
                            .use(router);
            installElementPlus(dialogApp,ref);
            installJhComponent(dialogApp);
            utils.$$tools.initCtx(dialogApp);
            config.bindAppProps(dialogApp,[
                {key:'$params',value:options},
                {key:'$axios',value:axios},
                {key:'utils',value:utils},
                {key:'validForbid',value:validForbid},
                {key:'excelUtils',value:export_json_to_excel},
                {key:'exportMulHeadExcel',value:export_mul_head_excel}
            ]);
            let divEle = document.createElement("div");
            divEle.setAttribute("id",options.dialogDiv);
            document.body.appendChild(divEle);
            const vm=dialogApp.mount(divEle);
            resolve(vm);
        });
    }
};