<template>
    <div id="pageList" class="personList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
<!--                    <el-col :span="3" class="myColTitle">编码：</el-col>-->
<!--                    <el-col :span="9">-->
<!--                        <el-input @input="e => pageList.queryParam.code = validForbid(e)" placeholder="请输入编码模糊匹配查询..." v-model="pageList.queryParam.code" clearable/>-->
<!--                    </el-col>-->
                    <el-col :span="3" class="myColTitle">名称：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.name = validForbid(e)" placeholder="请输入名称模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                </el-row>
            </template>
            <template v-slot:fixCol v-if="engineParams">
                <el-table-column type="selection" width="55"></el-table-column>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_NAME" label="名称"/>
<!--                <el-table-column prop="F_CORP_NAME" label="机构"/>-->
                <el-table-column prop="F_SEX" label="性别"/>
                <el-table-column prop="F_MOBILE" label="手机号码"/>
            </template>
        </ListEngine>
    </div>

</template>

<script>
    // import PersonListHelper from "@/views/sysviews/organization/person/PersonListHelper.js";
    import PersonListHelper from './PersonListHelper'

    export default PersonListHelper;
</script>

<style scoped>
    .personList{width: 100%;}
</style> 
