<template>
    <div class="roleMenu">
        <el-container>
            <el-header>
                <el-row>
                <template v-for="menu in topMenuTree.topMenus" :key="menu.menuId">
                    <el-tag :class="isActive(menu.menuId) ? 'active' : '' " @click="tagClick(menu.menuId)">{{menu.title}}</el-tag>
                </template>
                </el-row>
            </el-header>
            <el-main>
                <el-tree
                        ref="treeRef"
                        :data="treeData"
                        show-checkbox
                        node-key="id"
                        :props="defaultProps"
                        default-expand-all
                        :check-on-click-node="false"
                        :expand-on-click-node="false"
                >
                    <template #default="{ node, data }">
                       <span class="custom-tree-node">
                           <span>
                             {{ node.label }}
                           </span>
                           <span>
                                <div v-if="data.parentId=='-'">
                                  <el-button-group>
                                      <el-button plain class="iconfont icon-quanxuan" size="mini" @click.stop="selAll(node, data)">全选</el-button>
                                      <el-button plain class="iconfont icon-quxiao" size="mini" @click.stop="cancelAll(node, data)">取消</el-button>
                                  </el-button-group>
                               </div>
                               <el-select :key="data.id"
                                      v-if="node.isLeaf && data.menuItermPermission!='[]'"
                                      v-model="menuSelPermissionIterms[data.id]"
                                      multiple
                                      placeholder="请选择"
                               >
                                    <el-option
                                            v-for="item in JSON.parse(data.menuItermPermission)"
                                            :key="item.code"
                                            :label="item.name"
                                            :value="item.code"
                                    ></el-option>
                               </el-select>
                           </span>
                       </span>
                    </template>
                </el-tree>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import RoleMenuHelper from "@/views/sysviews/role/RoleMenuHelper.js";

    export default RoleMenuHelper;
</script>

<style scoped>
    .roleMenu ::v-deep(.el-tag){
        margin-right: 10px;
    }

    .roleMenu ::v-deep(.el-tag:hover){
        cursor: pointer;
    }

    .active {
        background-color: darkcyan;
    }

    .roleMenu ::v-deep(.el-header){
        height: 40px !important;
    }

    .roleMenu ::v-deep(.el-main){
        padding-top: 15px !important;
    }

    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
    /*.custom-tree-node span:nth-of-type(1){*/
    /*   margin-right: 100px;*/
    /*}*/
    /*.custom-tree-node span:nth-of-type(2){*/
    /*    flex: 1; */
    /*}*/

    .roleMenu ::v-deep(.el-tree-node__content){
        height: 40px;
    }

    .roleMenu ::v-deep(.el-select){
        width: 900px;
    }
</style>
