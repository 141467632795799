<template>
    <div class="personCard">
        <el-form ref="formRef" status-icon :model="form" :rules="rules" label-width="80px">
            <el-row>
                <el-col :span="11">
                    <el-form-item label="名称" prop="name">
                        <el-input @input="e => form.name = validForbid(e)" v-model="form.name" maxlength="50" placeholder="请输入名称" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="11" :offset="2">
                    <el-form-item label="身份证" prop="sfz">
                        <el-input @input="e => form.sfz = validForbid(e)" v-model="form.sfz" maxlength="30" placeholder="请输入身份证" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="11">
                    <el-form-item label="职位" prop="position">
                        <el-input @input="e => form.position = validForbid(e)" v-model="form.position" maxlength="100" placeholder="请输入职位" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="11" :offset="2">
                    <el-form-item label="出生日期">
                        <el-date-picker v-model="form.birthdate" placeholder="选择日期"/>
                    </el-form-item>
                </el-col>
                <el-col :span="11" :offset="2" v-show="false">
                    <el-form-item label="称谓" prop="title">
                        <el-input @input="e => form.title = validForbid(e)" v-model="form.title" maxlength="100" placeholder="请输入称谓" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row v-show="false">
                <el-col :span="11">
                    <el-form-item label="办公电话" prop="officeTel">
                        <el-input @input="e => form.officeTel = validForbid(e)" v-model="form.officeTel" maxlength="13" placeholder="请输入办公电话" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="11" :offset="2">
                    <el-form-item label="住宅电话" prop="homeTel">
                        <el-input @input="e => form.homeTel = validForbid(e)" v-model="form.homeTel" maxlength="13" placeholder="请输入住宅电话" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="11">
                    <el-form-item label="手机号码" prop="mobile">
<!--                        <el-input v-model="form.mobile" maxlength="11" placeholder="请输入手机" clearable/>-->
                        <el-input @input="e => form.mobile = validForbid(e)" v-model="form.mobile" placeholder="请输入手机号码" maxlength="11" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="11" :offset="2">
                    <el-form-item label="电子邮箱" prop="email">
                        <el-input @input="e => form.email = validForbid(e)" v-model="form.email" maxlength="40" placeholder="请输入电子邮箱" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="11">
                    <el-form-item label="性别">
                        <el-radio-group v-model="form.sex">
                            <el-radio :label="1">男</el-radio>
                            <el-radio :label="0">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>

            </el-row>

            <el-row>
                <el-col :span="24">
                    <el-form-item label="说明" prop="desc">
                        <el-input @input="e => form.desc = validForbid(e)" type="textarea" v-model="form.desc" maxlength="125" placeholder="请输入说明" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
    import { ref,computed,getCurrentInstance,reactive,toRefs,onMounted,defineComponent} from 'vue';
    export default defineComponent({
        name: "personCard",
        title: "人员管理",
        modelType:'card',
        fullscreen: true,
        setup(){
            let {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                formRef:null,
                corpTreeData: [],
                compParams: {
                    modelPath: "/person"
                },
                form: {
                    id: "",
                    name: "",
                    corpId: "",
                    deptId: "",

                    title: "",
                    position: "",
                    officeTel: "",
                    homeTel: "",
                    mobile: "",
                    email: "",
                    sex: 0,
                    birthdate: "",
                    sfz: "",
                    desc: ""
                },
                rules: {
                    name: [
                        {required: true, message: "请输入名称", trigger: "blur"}
                    ],
                    corpId: [
                        {required: true, message: "请输选择机构", trigger: "change"}
                    ],
                    // mobile: [
                    //     { validator: utils.$$str.checkPhone, trigger: 'blur' }
                    // ],
                    officeTel: [
                        { validator: (rule, value, callback)=>{utils.$$str.checkPhone(rule, value, callback,false)}, trigger: 'blur' }
                    ],
                    homeTel: [
                        { validator: (rule, value, callback)=>{utils.$$str.checkPhone(rule, value, callback,false)}, trigger: 'blur' }
                    ],
                    mobile: [
                        { validator: (rule, value, callback)=>{utils.$$str.checkMobilePhone(rule, value, callback,false)}, trigger: 'blur' }
                    ]
                }
            })

            const beforeOpen=async(data, addOrLoad)=>{
                dataObj.corpTreeData = await utils.$$api.corpTreeData();
            }
            const change=(val)=>{

            }

            //保存之前，校验整个保存数据是否合法
            const beforeSaveHandler=()=>{
                return true;
            }
            return{
                ...toRefs(dataObj),beforeOpen,change,beforeSaveHandler
            }
        },

        components: {}
    });
</script>

<style scoped>
    .personCard{width: 100%;}
</style>
