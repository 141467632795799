<template>
    <div class="corpCard">
        <el-form ref="formRef" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="编码" prop="code">
                <el-input @input="e => form.code = validForbid(e)" v-model="form.code" maxlength="10" placeholder="请输入编码" clearable/>
            </el-form-item>
            <el-form-item label="名称" prop="name">
                <el-input @input="e => form.name = validForbid(e)" v-model="form.name" maxlength="100" placeholder="请输入名称" clearable/>
            </el-form-item>


            <el-form-item label="地址" prop="addr">
                <el-input @input="e => form.addr = validForbid(e)" v-model="form.addr" maxlength="100" placeholder="请输入地址" clearable/>
            </el-form-item>
            <el-form-item label="电话" prop="tel">
                <el-input @input="e => form.tel = validForbid(e)" v-model="form.tel" maxlength="11" placeholder="请输入电话" clearable/>
            </el-form-item>
            <el-form-item label="联系人" prop="url">
                <el-input @input="e => form.contacts = validForbid(e)" v-model="form.contacts" maxlength="100" placeholder="请输入联系人" clearable/>
            </el-form-item>
            <el-form-item label="说明" prop="desc">
                <el-input @input="e => form.desc = validForbid(e)" type="textarea" v-model="form.desc" maxlength="100" placeholder="请输入说明" clearable/>
            </el-form-item>

        </el-form>
    </div>
</template>

<script>
    import {
        ref,
        computed,
        getCurrentInstance,
        reactive,
        toRefs,
        onMounted,
        defineComponent,
        nextTick,
        provide
    } from 'vue';
    import Upload from "../../../../components/base_comp/Upload";
    export default {
        name: "corpCard",
        title: "机构管理",
        modelType:'card',
        fullscreen: false,
        setup(){
            let {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            onMounted(()=>{

            })

            let checkTel = (rule, value, callback) => {
                const re = /^[0-9]*$/;
                if(value && !re.test(value)){
                    callback(new Error("请输入数字"));
                }else{
                    callback();
                }
            };
            let dataObj=reactive({
                formRef:null,
                parentTreeData: [],
                compParams: {
                    modelPath: "/corp"
                },
                form: {
                    id: "",
                    code: "",
                    name: "",
                    parentId: "",
                    addr: "",
                    tel: "",
                    contacts: "",
                    desc: ""
                },
                rules: {
                    code: [
                        {required: true, message: "请输入编码", trigger: "blur"}
                    ],
                    name: [
                        {required: true, message: "请输入名称", trigger: "blur"}
                    ],
                    tel: [
                        {validator: checkTel, trigger: "blur"}
                    ]
                }
            })

            const beforeOpen=async(res,addOrLoad,engineInst)=> {
                dataObj.parentTreeData = await utils.$$api.corpTreeData();
            }


            return{
                ...toRefs(dataObj),beforeOpen
            }
        },
        components: {Upload}
    };
</script>

<style scoped>
    .corpCard{width: 100%;}
</style>
