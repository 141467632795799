import UserCard from './UserCard';
import {ref, computed, getCurrentInstance, reactive, toRefs, onMounted, defineComponent, provide} from 'vue';
const UserListHelper = defineComponent({
    name: "userList",
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        let dataObj=reactive({
            pageListRef:null,
            operId: "",
            refMap:new Map(),
            corpTreeData: [],
            personData: [],
            pageList: {
                queryParam: {
                    code: "",
                    name: "",
                    corpId: "",
                    deptId: "",
                    personId: "",
                    status: ""
                },
                modelComp:UserCard,
                modelMethod: "/user/pageData"
            }
        })

        onMounted(async ()=>{
            await init();
            await buildPersonData();
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params) => {
                if(params && 'personId'==params.comboId){
                    return dataObj.personData;
                }
            }
        })

        const formatterStatus=(row, column, value, index)=>{
            if (0 == value) {
                return "启用";
            } else if (1 == value) {
                return "禁用";
            } else if (2 == value) {
                return "锁定";
            } else {
                return "";
            }
        }
        const init=async()=>{
            dataObj.corpTreeData = await utils.$$api.corpTreeData();
        }
        const corpChange=async(val)=>{

        }
        const buildPersonData=async ()=>{
            dataObj.personData= await utils.$$api.getPersons({});
        }
        return{
            ...toRefs(dataObj),comboSelect,formatterStatus,corpChange,buildPersonData
        }
    }
});
export default UserListHelper;