<template>
    <div class="verfiCode">
        <el-button type="success" plain @click="createCode" style="width: 100%;">{{genCode}}</el-button>
    </div>
</template>

<script>
    export default {
        name: "VerfiCode",
        data() {
            return {
                genCode:"",
                checkCode:""
            }
        },
        created() {
            this.createCode();
        },
        methods: { // 图片验证码
            createCode() {
                //先清空验证码的输入
                this.checkCode = "";
                this.genCode = "";
                //验证码的长度
                var codeLength = 4;
                //随机数
                var random = new Array(2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N','P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z');
                for (var i = 0; i < codeLength; i++) {
                    //取得随机数的索引（0~31）
                    var index = Math.floor(Math.random() * random.length);
                    //根据索引取得随机数加到code上
                    this.genCode += random[index];
                }
                //把code值赋给验证码
                this.checkCode = this.genCode;
            },
            verfiCode(inputCode){
                return inputCode.toUpperCase()==this.genCode;
            }
        }
    }
</script>

<style scoped>
.verfiCode{
    width: 100%;
    display: flex;
    align-items: center;
}

</style>