<template>
    <div class="cardEngine" v-loading="loading">
        <el-container>
            <el-header style="height: 40px">
                <el-row>
                    <template v-for="btn in buttons">
                        <el-button v-if="btn.type" :type="btn.type" :class="btn.icon" @click="clickHandler(btn.clickEvent)" :key="btn.id" >{{btn.title}}</el-button>
                        <el-button v-else :class="btn.icon" @click="clickHandler(btn.clickEvent)" :key="btn.id" >{{btn.title}}</el-button>
                    </template>
                </el-row>
            </el-header>

            <div :style="{'max-height':mainMaxHeight+'px',overflow: 'auto'}" class="dialogContainer">
                <el-main>
                    <el-card>
                        <component ref="modelCompRef" :is="modelComp"></component>
                    </el-card>
                </el-main>
            </div>
        </el-container>

        <!--单据审批日志和附件抽屉-->
        <el-drawer
                title="审批日志信息" :show-close="true" size="40%"
                v-model="approveLog" :append-to-body="true"
                direction="rtl">
            <span style="margin-left: 10px;"><i class="el-icon-message"></i> 审批意见</span>
            <div class="radio" style="margin-bottom: 10px;margin-left: 10px;">
                排序：
                <el-radio-group v-model="reverse" @change="logInfoArr.reverse()">
                    <el-radio :label="true">倒序</el-radio>
                    <el-radio :label="false">正序</el-radio>
                </el-radio-group>
            </div>
            <div class="approveLogContent" :style="{height:approveLogContentHeight+'px'}">
                <el-backtop target=".approveLogContent"/>
                <el-timeline :reverse="!reverse">
                    <el-timeline-item placement="top" v-for="(activity, index) in logInfoArr" :key="index" :timestamp="activity.time">
                        <el-card>
                            <div v-html="activity.remark"></div>
                        </el-card>
                    </el-timeline-item>
                </el-timeline>
            </div>
        </el-drawer>


        <el-backtop target=".dialogContainer"></el-backtop>
    </div>
</template>

<script>
    import CardEngineHelper from "./CardEngineHelper";
    export default CardEngineHelper;
</script>

<style scoped>
    .cardEngine{
        width: 100%;
    }

    .el-main{
        padding: 5px 20px 20px 20px !important;
    }
    .approveLogContent {
        overflow-y: auto;
        margin-left: 8px;
    }

    /*滚动条样式,app.vue 里面是全局的，这里要单独改变一下大小，否则看不到滚动条*/
    ::-webkit-scrollbar {
        width: 10px;
    }
</style>