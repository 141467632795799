const AFTERPAGEDATA = "afterPageData";
const GRIDLOADED = "gridLoaded";
const BEFOREDELETEHANDLER = "beforeDeleteHandler";
const AFTERDELETEHANDLER = "afterDeleteHandler";
const DELETEHANDLER = "deleteHandler";
const EDITHANDLER = "editHandler";
const ADDHANDLER = "addHandler";
const QUERYHANDLER = "queryHandler";
const SUREHANDLER = "sureHandler";
const CANCELHANDLER = "cancelHandler";
const EXPORTEXCEL = "exportExcel";
const ISSHOWDEL = "isShowDel";
const ISSHOWEDIT = "isShowEdit";
import {useRoute} from "vue-router";
import { ref,reactive,toRefs,onMounted,computed,getCurrentInstance,provide,nextTick,defineComponent,inject} from 'vue'
const listEngine = defineComponent({
    name: "ListEngine",
    props:{
        pageList: {
            type: Object,
            default:()=>{return{}}
        }
    },
    setup(props,context){
        const route = useRoute();
        const appInstant = getCurrentInstance();
        let {proxy}=getCurrentInstance();
        let parentInst=appInstant.parent.proxy;
        const utils=proxy.utils;
        let pageParams=props.pageList;
        const tbRef=ref(null);
        provide('gridTableOwner', appInstant.parent.proxy);
        let mainComp = inject('mainComp');
        let dataObj=reactive({
            mainComp:mainComp,
            fullscreenLoading:true,
            exportExcelVisible: false,
            backData: {},

            collapseTitle:'查询条件(点击展开)',
            queryConditionHeight: 0,
            queryHeight:31,
            btnGroupHeight:0,

            queryCondition:'queryCondition',
            listButtons: [],
            listParam: {idField:"F_ID",isShowFixCol: true,isShowBtnGroup: true, isShowQueryParam: true,autoLoad:false,showTitle:true,showToolBar:false,expandQuery:true},
            exportType:0
        })

        const init=()=> {
            let title='';
            if(pageParams.title){
                title=pageParams.title;
            }else if(pageParams.modelComp)title=pageParams.modelComp.title;
            if(''==title){
                title=route.meta.title;
            }
            dataObj.listParam = Object.assign({},dataObj.listParam, pageParams,{title:title});
            dataObj.btnGroupHeight=dataObj.listParam.isShowBtnGroup?41:0;
            let conditionRowNum = 0;
            let rowHeight=39;
            let elementUISize=localStorage.getItem("elementUISize");
            switch (elementUISize) {
                case 'large':rowHeight=46;break;
                case 'medium':rowHeight=42;break;
                case 'mini':rowHeight=35;break;
            }
            if (dataObj.listParam.isShowQueryParam) {
                if(context.slots.queryParams){
                    let queryParams=context.slots.queryParams();
                    // conditionRowNum = queryParams.length;
                    for(let i=0;i<queryParams.length;i++){
                        if(queryParams[i].props)conditionRowNum++;
                    }
                    dataObj.queryConditionHeight = conditionRowNum * rowHeight;
                }
            }else{
                dataObj.queryHeight=0;
            }
        }
        init();
        onMounted(()=>{
            nextTick(async()=>{
                calTbMaxHeight();
                await loadPageData();
                if(dataObj.listParam.expandQuery){
                    dataObj.queryHeight=dataObj.queryConditionHeight+31;
                    calTbMaxHeight();
                    dataObj.collapseTitle='查询条件(点击关闭)';
                }
            })
            window.onresize = ()=> {
                if(window.innerHeight){
                    calTbMaxHeight();
                }
            }
        })

        const isShowDel=computed(()=>{
            return (row) => {
                let isShow=dataObj.backData.canDel;
                if(parentInst[ISSHOWDEL])isShow=parentInst[ISSHOWDEL](row);
                return isShow;
            };
        })
        const isShowEdit=computed(()=>{
            return (row) => {
                let isShow=dataObj.backData.canEdit || dataObj.backData.canView;
                if(parentInst[ISSHOWEDIT])isShow=parentInst[ISSHOWEDIT](row);
                return isShow;
            };
        })
        const loadPageData=async()=>{
            let queryParam=pageParams.queryParam;
            if(!queryParam)queryParam={};
            queryParam.pageSize = tbRef.value.tbPagerInitParam.pageSize;
            queryParam.currentPage = tbRef.value.currentPage;
            queryParam.canPage = tbRef.value.canPage;
            let res = await utils.$$api.postRequest({url: pageParams.modelMethod, params: queryParam});

            if(parentInst[AFTERPAGEDATA]){
                parentInst[AFTERPAGEDATA](res);
            }else{
                if(tbRef.value)tbRef.value.tbData = res.rows;
                if(tbRef.value)tbRef.value.total = res.total;
                if (parentInst[GRIDLOADED]) {
                    parentInst[GRIDLOADED](res);
                }
            }
            dataObj.backData = res;
            dataObj.listButtons = res.buttons;
            dataObj.fullscreenLoading=false;
        }
        const clickHandler=(ev)=>{
            if (parentInst[ev]) {
                parentInst[ev]();
            } else {
                proxy[ev]();
            }
        }
        const queryHandler=()=>{
            if (parentInst[QUERYHANDLER]) {
                parentInst[QUERYHANDLER]();
            } else {
                if (parentInst["beforeQuery"]){
                    let beforeQueryResult=parentInst["beforeQuery"](proxy)
                    beforeQueryResult.then(res=>{
                        if(res){
                            tbRef.value.queryHandler(true);
                        }
                    })
                }else{
                    tbRef.value.queryHandler(true);
                }
            }
        }
        const addHandler=async ()=>{
            if (parentInst[ADDHANDLER]) {
                parentInst[ADDHANDLER]();
            } else {
                await showModule({addOrEdit: 'add', id: '',dialogDiv:utils.$$str.randomString(6)});
            }
        }
        const editHandler=async (row)=>{
            if (parentInst[EDITHANDLER]) {
                parentInst[EDITHANDLER](row);
            } else {
                await showModule({addOrEdit: 'edit', id: row[dataObj.listParam.idField],dialogDiv:utils.$$str.randomString(6)});
            }
        }
        const showModule=async(options)=>{
            options = Object.assign({}, {ownerComp: proxy}, dataObj.listParam,options);
            let vm = await utils.$$dialog.create(options);
            vm.dialogVisible = true;
        }
        const sureHandler=(modelEngineRef)=>{
            if (parentInst[SUREHANDLER]) {
                return parentInst[SUREHANDLER](modelEngineRef);
            }else{
                return true;
            }
        }
        const cancelHandler=(modelEngineRef)=>{
            if (parentInst[CANCELHANDLER]) {
                return parentInst[CANCELHANDLER](modelEngineRef);
            }else{
                return true;
            }
        }
        const sureExport=async()=>{
            const loading = proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
            switch (dataObj.exportType) {
                case 0:
                    await exportExcel(tbRef.value.getTbData());
                    break;
                case 1:
                    let queryParam=pageParams.queryParam;
                    queryParam.canPage=false;
                    let modelPathArr=dataObj.listParam.modelMethod.split('/');
                    let modelPath="/"+modelPathArr[1];
                    // let res = await utils.$$api.postRequest({url: modelPath + "/exportExcelData", params: {canPage: false}});
                    let res = await utils.$$api.postRequest({url: modelPath + "/exportExcelData", params: queryParam});
                    await exportExcel(res.rows);
                    break;
            }
            dataObj.exportExcelVisible = false;
            loading.close();
            proxy.$message({showClose: true, message: '导出成功', type: 'success'});
        }
        const formatJson=(fields, jsonData)=>{
            return jsonData.map(rowData => fields.map(field => rowData[field]))
        }
        const exportExcel=(tbData)=>{
            return new Promise((resolve, reject) => {
                const data = formatJson(tbRef.value.tbCols.fields, tbData);
                let fields=tbRef.value.tbCols.fields;
                let labels=tbRef.value.tbCols.labels;
                if (parentInst[EXPORTEXCEL]) {
                    parentInst[EXPORTEXCEL](fields, labels,data,proxy);
                    resolve();
                } else {
                    proxy.excelUtils(labels, data, dataObj.listParam.title + "电子表格");
                    resolve();
                }
            }).catch(err => {
                console.log('导出excel出错了')
            });
        }
        const exportExcelHandler=()=>{
            
            dataObj.exportExcelVisible = true;
        }
        const deleteHandler=(row)=>{
            if (parentInst[DELETEHANDLER]) {
                parentInst[DELETEHANDLER](row);
            } else {
                utils.$$tools.configBox({
                    fn:async ()=> {
                        await doDelete(row)
                    }
                });
            }
        }
        const doDelete=async(row)=>{
            if (parentInst[BEFOREDELETEHANDLER] && !parentInst[BEFOREDELETEHANDLER](row)) {//抛出删除之前接口
                return;
            }
            let modelPathArr=dataObj.listParam.modelMethod.split('/');
            let modelPath="/"+modelPathArr[1];
            const loading = proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
            let res = await utils.$$api.postRequest({url: modelPath + "/delete", params: {id: row[dataObj.listParam.idField]}});
            loading.close();
            if(res.result){
                utils.$$tools.success({message: res.msg});
                if (parentInst[AFTERDELETEHANDLER]) parentInst[AFTERDELETEHANDLER](row);
                tbRef.value.queryHandler(false);
            }
        }
        const handleChange=(val)=> {
            let conditionHeight = dataObj.listParam.isShowQueryParam ? dataObj.queryConditionHeight : 0;
            if (val.length != 1) {
                conditionHeight = 0;
                dataObj.queryHeight=31;
                dataObj.collapseTitle='查询条件(点击展开)';
            }else{
                dataObj.collapseTitle='查询条件(点击折叠)';
            }
            dataObj.queryHeight=dataObj.queryHeight+conditionHeight;
            calTbMaxHeight();
        }
        const setTbData=(tbData)=>{//设置表格数据
            tbRef.value.setTbData(tbData);
        }
        const getTbData=()=> {//得到表格数据
            return tbRef.value.getTbData();
        }
        const getTbInst=()=>{//得到表格引用
            return tbRef.value;
        }
        //重新计算设置表格最大高度
        const calTbMaxHeight=()=> {
            let page=32;
            if(!tbRef.value.canPage){
                page=0;
            }
            let maxTbHeight=(window.innerHeight-dataObj.btnGroupHeight-dataObj.queryHeight-utils.$$const.baseVar.topHeight-utils.$$const.baseVar.tabHeight-page);
            if(dataObj.listParam.showTitle)maxTbHeight=maxTbHeight-utils.$$const.baseVar.tbHeaderHeight;
            if(dataObj.listParam.showToolBar)maxTbHeight=maxTbHeight-utils.$$const.baseVar.tbToolBarHeight;
            if(dataObj.listParam.reduceOthers)maxTbHeight=maxTbHeight-dataObj.listParam.reduceOthers;
            tbRef.value.setMaxTbHeight(maxTbHeight);
        }
        const setBtnEnable=(val,id)=>{
            if(!id)id='add';
            let btn=dataObj.listButtons.find((btn) => btn.id==id);
            if(btn)btn.disabled=val;
        }
        const hasOperatePermission=()=>{
            return (dataObj.backData.canDel || dataObj.backData.canEdit || dataObj.backData.canView)
        }

        return{
            ...toRefs(dataObj),isShowDel,isShowEdit,tbRef,loadPageData,queryHandler,addHandler,
            sureHandler,cancelHandler,clickHandler,editHandler,deleteHandler,sureExport,exportExcelHandler,
            handleChange,setTbData,getTbData,getTbInst,calTbMaxHeight,setBtnEnable,hasOperatePermission
        }
    }
});
export default listEngine;