<template>
    <div class="editTable">
        <GridTable ref="detailTypeRef" v-bind="editParams">
            <template v-slot:_tbToolBar v-if="editParams.showToolBar && !editParams.readOnly">
                <el-row style="margin: 1px;">
                    <el-button-group>
                        <!--默认显示新增按钮、不显示上移、下移按钮-->
                        <el-button plain icon="el-icon-plus" v-if="editParams.canAdd && !editParams.readOnly" @click="addDetailHandler()">新增</el-button>
                        <el-button plain icon="el-icon-top" v-if="editParams.canUp && !editParams.readOnly" @click="upHandler()">上移</el-button>
                        <el-button plain icon="el-icon-bottom" v-if="editParams.canDown && !editParams.readOnly" @click="downHandler()">下移</el-button>
                        <!--自定义toolbar-->
                        <el-button v-for="btn in editParams.toolBar" :key="btn.id" :type="btn.type" :icon="btn.icon" @click="clickHandler(btn.clickEvent,btn.param)" v-show="!editParams.readOnly">{{btn.title}}</el-button>
                    </el-button-group>
                </el-row>
            </template>
            <template v-slot:_tbCols>
                <!--可编辑表格组件默认提供了操作列：删除，固定在左边，序列之后-->
                <slot name="fixCol" v-if="editParams.isShowFixCol && !editParams.readOnly">
                    <el-table-column header-align="center" align="center" label="操作" fixed width="70">
                        <template #default="scope">
                            <el-button @click.stop="delDetailHandler(scope.row,scope.$index)" type="text">
                                <div style="color:red"><i class="el-icon-delete" style="color: red"/>&nbsp;&nbsp;删除</div>
                            </el-button>
                        </template>
                    </el-table-column>
                </slot>
                <slot name="tbCols"/>
            </template>
        </GridTable>
    </div>
</template>

<script>
    import EditTable from "@/components/base_comp/EditTableHelper.js";
    export default EditTable;
</script>

<style scoped>
    .editTable{
        /*padding-left: 30px;*/
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .titleFont{
        font-size: 12px;
        font-weight: bold;
        color: rgb(144, 147, 153);
        margin-left: 10px;
        /*让标题纵向居中*/
        display: flex;
        align-items: center;
        height: 100%;
    }
</style>
