<template>
    <div :class="slideBarClass" :style="{height:leftHeight+'px',backgroundColor:backgroundColor}">
        <el-menu
                :collapse="isCollapse"
                :default-active="route.name"
                class="menuCss"
                :background-color="backgroundColor"
                text-color="#fff"
                active-text-color="#409eff"
        >
            <MenuIterm :userMenus="userMenus" ref="menuItemRef"></MenuIterm>
        </el-menu>
    </div>
</template>

<script>
    import MenuIterm from "@/components/layout/MenuIterm";
    import { ref,reactive,toRefs,onMounted,computed,getCurrentInstance,defineComponent} from 'vue';
    import {useRoute} from "vue-router";
    export default defineComponent({
        name: "SlideBar",
        setup(){
            const {proxy} = getCurrentInstance();
            const utils=proxy.utils;
            const route = useRoute();
            const dataObj=reactive({//#324157
                menuItemRef:null,
                backgroundColor:'#242834',
                isCollapse: false,
                leftHeight:500,
                userMenus: {}
            });
            dataObj.userMenus = JSON.parse(utils.$$str.decrypt(sessionStorage.getItem("userMenus")));

            onMounted(()=>{
                dataObj.leftHeight=(window.innerHeight-utils.$$const.baseVar.topHeight);
                window.addEventListener('resize', () => {
                    dataObj.leftHeight=(window.innerHeight-utils.$$const.baseVar.topHeight);
                }, false)
            })
            //---------------------------computed------------
            const slideBarClass=computed(()=>{
                return {
                    slideBar_big: !dataObj.isCollapse,
                    slideBar_small: dataObj.isCollapse
                };
            })
            const changeMenu=(menus)=>{//动态更改左侧菜单，由top组件调用
                dataObj.menuItemRef.userMenus=menus;
            }
            return{
               ...toRefs(dataObj),slideBarClass,changeMenu,route
            }
        },
        components: {
            MenuIterm
        }
    });
</script>

<style scoped>
    .slideBar_big {
        position: fixed;
        width: 200px;
        top: 56px;
        overflow-y: auto;
    }

    .slideBar_small {
        position: fixed;
        width: 64px;
        top: 56px;
        overflow-y: auto;
    }

    .menuCss {
        height: 100%;
        border-right: none !important;
    }
</style>